import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {Suspense, useContext, useEffect, useState} from 'react';
import {AccountCard} from 'titan-ads/lib/components/AccountCard';
import {List} from 'titan-ads/lib/components/List';
import {Ad,AdSet,BrandAccount,Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {QuoraAccount} from 'titan-ads/lib/shapes/QuoraAccount';
import {CreateNewAccount} from '../components/CreateNewAccount';
import {Spinner} from '../components/Spinner';
import {ThemeContext} from '../contexts/Theme';
import {DefaultLayout} from '../layout/DefaultLayout';
import {useSuspense} from '../utils';
import './Accounts.scss';
import * as styles from './Accounts.scss.json';

export default function Accounts() {
  const [quoraAccounts, setQuoraAccounts] = useState<{
    read(): ShapeSet<QuoraAccount>;
  }>({
    read() {
      return new ShapeSet();
    },
  });

  const [brands, setBrands] = useState<{
    read(): ShapeSet<BrandAccount>;
  }>({
    read() {
      return new ShapeSet();
    },
  });

  useEffect(() => {
    Ad.loadAll();
    AdSet.loadAll();
    Campaign.loadAll();

    setBrands(
      useSuspense(
        BrandAccount.loadAll().then((a) => {
          if(process.env.DEBUG_PRODUCTION_DATA) {
            return a;
          }
          if(process.env.NODE_ENV === 'production') {
            return a.filter((a) => !a.name.toLowerCase().includes("lincd") && !a.name.toLowerCase().includes("semantu"));
          } else {
            return a.filter((a) => a.name.toLowerCase().includes("lincd") || a.name.toLowerCase().includes("semantu"));
          }
        }),
      ),
    );
    setQuoraAccounts(
      useSuspense(
        QuoraAccount.loadAll().then((a) => {
          console.log(a);
          return a;
        }),
      ),
    );
  }, []);

  const updateQuoraAccounts = async () => {
    const fetchFromQuora = true;
    setQuoraAccounts(useSuspense(QuoraAccount.loadAll(fetchFromQuora)));
  };

  const [foo, setFoo] = useState(false);
  const forceRender = () => setFoo(!foo);

  const {isLight} = useContext(ThemeContext);

  return (
    <DefaultLayout>
      <h1>Create a new Brand Account</h1>
      <div className={styles.createAccount}>
        <CreateNewAccount forceRender={forceRender} />
      </div>
      <Suspense fallback={<Spinner />}>
        <List
          // AccountCard props
          isLight={isLight}
          quoraAccounts={quoraAccounts}
          updateQuoraAccounts={updateQuoraAccounts}
          // List props
          resource={brands}
          renderAs={AccountCard}
        />
      </Suspense>
    </DefaultLayout>
  );
}
