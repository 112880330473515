/**
 * Popover component
 * use https://www.radix-ui.com/primitives/docs/components/popover
 * copy from https://github.com/Semantu/lincd.org/blob/mynd/api/modules/primitives/src/components/Popover.tsx
 */

import * as React from 'react';

import './Popover.scss';
import style from './Popover.scss.json';
import { cl } from 'lincd/lib/utils/ClassNames';
import * as PopoverPrimitive from '@radix-ui/react-popover';

function extend<C extends React.FunctionComponent<any>>(
  Component: C,
  baseClassName: string,
) {
  let extended = React.forwardRef<
    React.ElementRef<C>,
    React.ComponentPropsWithoutRef<C>
  >(({className, ...props}, ref) =>
    React.createElement(Component, {
      ref,
      className: cl(baseClassName, className),
      ...props,
    }),
  );
  extended.displayName = Component.displayName;
  return extended;
}

const Root = PopoverPrimitive.Root;
const Trigger = PopoverPrimitive.Trigger;
const Anchor = PopoverPrimitive.Anchor;

const Arrow = extend(PopoverPrimitive.Arrow, style.Arrow);
const Close = extend(PopoverPrimitive.Close, '');

interface ContentProps extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> {
  hideClose?: boolean;
  children: React.ReactNode;
  sideOffset?: number;
  align?: 'start' | 'center' | 'end';
  className?: string;
}
const Content = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  ContentProps
>(({className, align = 'center', sideOffset = 4, hideClose = false, children,  ...props}, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cl(style.Content, className)}
      {...props}
    >
      {children}
      {!hideClose && (
        <Close className={style.Close}>
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
              fill="currentColor"
              fillRule="evenodd"
              clipRule="evenodd"
            ></path>
          </svg>
        </Close>
      )}
    </PopoverPrimitive.Content>
  </PopoverPrimitive.Portal>
));
Content.displayName = PopoverPrimitive.Content.displayName;

export const Popover = {
  Root,
  Trigger,
  Anchor,
  Content,
  Arrow,
  Close,
};

export {Root, Trigger, Anchor, Content, Arrow, Close};