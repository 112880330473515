import {Server} from 'lincd-server-utils/lib/utils/Server';
import {useContext, useMemo, useState} from 'react';
import VideoThumbnail from 'react-video-thumbnail';
import {ThemeContext} from '../contexts/Theme';
import {packageName} from '../package';
import '../pages/Quicklaunch.scss';
import style from '../pages/Quicklaunch.scss.json';
import {AdSet,Ad} from 'titan-ads/lib/shapes/TitanShapes';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {AdVariationOutput, VariationContent} from '../types';
import {DashboardContext} from '../contexts/Dashboard';
import {PopulateMasterURL} from './InputGroup';
import {Spinner} from './Spinner';
import {InputsContext} from '../contexts/AdInputs';
import {asset} from 'lincd/lib/utils/LinkedFileStorage';
import {getInputsByPlatform, getPartialKeyByTitle} from 'titan-ads/lib/utils';

interface AdsPreviewProps {
  constraintAdSets: ShapeSet<AdSet>;
  currentIndex: number;
  brandProperty: any;
  generateOutput: (variationContent: VariationContent[]) => any[];
  goToPrev;
  goToNext;
  saving: boolean;
  setSaving: (val: boolean) => void;
  data;
  businessName?:string;
  variationContent: VariationContent[];
}

const QUICKLAUNCH_GROUP_ID: number = -1;

export default function AdsPreview({
  constraintAdSets,
  generateOutput,
  goToPrev,
  goToNext,
  brandProperty,
  businessName,
  data,
  variationContent,
  saving,
  setSaving,
  ...props
}: AdsPreviewProps) {
  const {curAccount, masterUrl, landingPageName} = useContext(DashboardContext);
  const {inputGroups} = useContext(InputsContext);
  const [createdAds, setCreatedAds] = useState<ShapeSet<Ad>>(null);
  const [resultsEmail, setResultsEmail] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<boolean>();

  const getAdsVariables = () => {
    const QUICKLAUNCH_GROUP_ID: number = -1;
    const {inputs} = inputGroups[QUICKLAUNCH_GROUP_ID];
    const internalPartials = getInputsByPlatform('quora').filter(
      (partial) => partial.internal,
    );
    // We only want to send the necessary internal variables to the backend,
    // and not a bunch of empty data
    const internalVariableEntries = Object.entries(inputs).filter(
      ([key, value], _) =>
        internalPartials
          .map((partial) => getPartialKeyByTitle('quora', partial.title))
          .includes(key),
    );
    // return internalVariables;
    return Object.fromEntries(internalVariableEntries);
  };
  const handleSubmit = () => {
    setSaving(true);
    //update: we are no longer waiting for quicklaunch to complete. so we just make the call and continue, no await
    Server.call(
      packageName,
      'quicklaunch',
      variationContent,
      constraintAdSets,
      curAccount,
      masterUrl,
      landingPageName,
      resultsEmail,
      getAdsVariables(),
      businessName,
    ).catch((err) => {
      alert(
        "Something went wrong. Please make a screenshot and send it to service@semantu.com and we'll sort it asap",
      );
      console.error(err);
      //we only set saving back to false if something goes wrong, to show the original input again
      setSaving(false);
    });
    setSuccessMessage(true);
  }

  const {isLight} = useContext(ThemeContext);

  const totalVariations = useMemo(
    () => generateOutput(variationContent),
    [variationContent],
  );

  const numOfAdsToLaunch = useMemo(
    () => totalVariations.length * constraintAdSets.size,
    [totalVariations, constraintAdSets],
  );

  return (
    <>
      <h1>
        Ads Preview ({totalVariations.length} variation
        {totalVariations.length !== 1 ? 's' : ''} × {constraintAdSets.size}{' '}
        adset
        {constraintAdSets.size !== 1 ? 's' : ''} = {numOfAdsToLaunch} ad
        {numOfAdsToLaunch !== 1 ? 's' : ''})
      </h1>
      <div className={style.headingNavigation}>
        <button onClick={goToPrev}>Previous</button>
        <button onClick={goToNext}>Next</button>
      </div>
      <div className={style.mediaList}>
        {totalVariations.map((item, index) => (
          <VariationPreview
            {...props}
            key={index}
            item={item}
            index={index}
            brandProperty={brandProperty}
            isLight={isLight}
            // landingPages={landingPages}
            // setLandingPages={setLandingPages}
            variationContent={variationContent}
          />
        ))}
      </div>
      <div className={style.emailInput}>
        <label>
          Send results to:{' '}
          <input
            type={'email'}
            name={'email'}
            aria-label={'Email address'}
            placeholder={'Email address'}
            onChange={(e) => setResultsEmail(e.currentTarget.value)}
          />
        </label>
      </div>
      {successMessage && (
        <div className={isLight ? style.message : style.messageDark}>
          Creating {numOfAdsToLaunch} ads. This will take about{' '}
          {Math.ceil((numOfAdsToLaunch * 20) / 60)} minute
          {Math.ceil((numOfAdsToLaunch * 20) / 60) > 1 ? 's' : ''}.<br />
          All ads will automatically be paused.
          <br />
          The results will be sent to {resultsEmail}
          <br />
          You can leave this page now. But it's best not to create more ads until you receive the results.
        </div>
      )}
      <div className={style.buttonContainer}>
        {successMessage || createdAds?.size > 0 ? (
          <button
            className={style.createButton}
            onClick={() => window.location.reload()}
          >
            Reset page
          </button>
        ) : (
          <button
            disabled={saving || constraintAdSets.size === 0 || !curAccount}
            style={{
              cursor: constraintAdSets.size === 0 ? 'not-allowed' : 'pointer',
            }}
            className={style.createButton}
            title={
              constraintAdSets.size === 0
                ? 'Please select some templates'
                : !curAccount
                ? 'Choose an account'
                : 'Create ads!'
            }
            onClick={handleSubmit}
          >
            {saving ? 'Creating...' : 'Create'}
          </button>
        )}
      </div>
      {createdAds?.size > 0 && (
        <div>
          <h2>Successfully created ads</h2>
          <PopulateMasterURL ads={createdAds} />
        </div>
      )}

      {/*{saving && !createdAds && (*/}
      {/*  <div className={style.message}>*/}
      {/*    <Spinner />*/}
      {/*    Creating ads... this will about 15 seconds per ad.*/}
      {/*    <br />*/}
      {/*    So up to {Math.ceil((totalVariations.length * 15) / 60)} minute*/}
      {/*    {Math.ceil((totalVariations.length * 15) / 60) > 1 ? 's' : ''} total.*/}
      {/*    <br />*/}
      {/*    All ads will automatically be paused.*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  );
}

function capitalizeWords(sentence) {
  // Split the sentence into words
  const words = sentence.split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the capitalized words to form the final string
  return capitalizedWords.join(' ');
}

interface VariationPreviewProps {
  brandProperty: string;
  currentIndex: number;
  index: number;
  isLight: boolean;
  item: AdVariationOutput;
  // landingPages: string[];
  // setLandingPages: Dispatch<SetStateAction<string[]>>;
  variationContent: VariationContent[];
}

function VariationPreview({
  brandProperty,
  currentIndex,
  index,
  isLight,
  item,
  // landingPages,
  // setLandingPages,
  variationContent,
}: VariationPreviewProps) {
  // const [landingPageURL, setLandingPageURL] = useState<string>('');

  // useEffect(() => {
  //   setLandingPages(() => {
  //     const newLandingPages = [...landingPages];
  //     newLandingPages[index] = landingPageURL;
  //
  //     return newLandingPages;
  //   });
  // }, [landingPageURL]);

  return (
    <div
      key={index}
      className={isLight ? style.mediaContainer : style.mediaContainerDark}
      style={{transform: `translateX(-${currentIndex * 100}%)`}}
    >
      <div className={style.adContain}>
        {item.adType === 'promoted_answer' && (
          <p className={style.promoted}>Promoted by {brandProperty}</p>
        )}
        <div className={style.logoSponsor}>
          {item.adType !== 'text_ad' && (
            <img
              key={index}
              src={
                item.adType === 'promoted_answer'
                  ? 'https://qph.cf2.quoracdn.net/main-thumb-55866273-50-ianwrpyvhdonyaiozlpjpkblgiikrhzy.jpeg'
                  : variationContent[0].logo.length > 0 &&
                    variationContent[0].logo[0].quoraCDN
                  ? variationContent[0].logo[0].quoraCDN
                  : asset('/images/titanlogo.png')
              }
            />
          )}
          <div>
            <h4>
              {item.adType === 'promoted_answer' ? 'Quora' : brandProperty}
            </h4>
            <p>
              {' '}
              {item.adType === 'promoted_answer'
                ? 'Official company account'
                : `Sponsored by ${brandProperty}`}
            </p>
          </div>
        </div>
        <div className={style.adsContent}>
          <h2>{item.title}</h2>
          <p>{item.contentText}</p>
        </div>
      </div>
      {item.adType !== 'text_ad' && item.adType !== 'promoted_answer' && (
        <div>
          {item.adType === 'video_ad' ? (
            <VideoThumbnail videoUrl={item.videoHash} />
          ) : item.imageUrl ? (
            <img src={item.imageUrl} alt="Media" width={80} />
          ) : (
            <iframe
              src={item.answerUrl}
              width={'100%'}
              height={'320px'}
              frameBorder="0"
            />
          )}
        </div>
      )}
      {/*<input*/}
      {/*  className={style.landingPageInput}*/}
      {/*  onChange={(e) => setLandingPageURL(e.target.value)}*/}
      {/*  placeholder="Landing Page URL..."*/}
      {/*  type="url"*/}
      {/*  value={landingPageURL}*/}
      {/*/>*/}
      {item.cta && (
        <button className={style.buttonCTA}>
          {capitalizeWords(item.cta.replace('_', ' '))}
        </button>
      )}
    </div>
  );
}
