import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import './ShapeTable.scss';
import style from './ShapeTable.scss.json';
import {Icons} from './Icons';
import {cl} from 'lincd/lib/utils/ClassNames';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {NodeShape} from 'lincd/lib/shapes/SHACL';
import {Spinner} from './Spinner';
import { QuickFilter, QuickFilters, TimeRangeProps } from '../types';
import { FilterTimeRange } from './FilterTimeRange';
import { BrandAccountSelector } from './RefreshQuoraData';
import { useSuspense } from '../utils';
import { BrandAccount } from 'titan-ads/lib/shapes/TitanShapes';
import { Popover } from './Popover';
import { DashboardContext } from '../contexts/Dashboard';
import {FilterComponent} from './FilterComponent';

interface ShapeTable {
  data: any | ShapeSet<any>;
  columns: ColumnDef<any>[];
  defaultColumn?:Partial<ColumnDef<any>>;
  pageReport?: string;
  filterInstances: (filters: any) => void;
  resetFilter: (value: boolean) => void;
  toggleSelectAll: () => void;
  isRowSelected: (id: string) => boolean;
  selectedRows: string[];
  toggleRowSelection: (id: string) => void;
  isDeleteBtnShowing: boolean;
  shape?: NodeShape;
  pagination: {pageIndex: number; pageSize: number};
  totalItems: number;
  onPaginationChange: (newPagination: {
    pageIndex: number;
    pageSize: number;
  }) => void;
  sorting: Array<any>;
  onSortingChange: (any) => void;
  onSearchChange: (any) => void;
  timeRange?: TimeRangeProps;
  onTimeRangeChange?: (timeRange: TimeRangeProps) => void;
  isLight?: boolean;
  isLoading?:boolean;
  quickFilter?: QuickFilters[];
  batchActionDropdown?: React.ReactNode;
  dateFilter?: React.ReactNode;
  onDetailsClick: (any) => void;
}

const initialAccounts = useSuspense(BrandAccount.loadAll());

function ShapeTable({
  data,
  columns,
  defaultColumn,
  toggleSelectAll,
  filterInstances,
  resetFilter,
  isRowSelected,
  isLoading,
  selectedRows,
  toggleRowSelection,
  isDeleteBtnShowing,
  shape,
  pagination, // Receive pagination state
  totalItems, // Receive total items count
  onPaginationChange, // Receive pagination change handler
  sorting,
  quickFilter,
  onSortingChange,
  onSearchChange,
  isLight,
  batchActionDropdown,
  dateFilter,
  onDetailsClick,
  timeRange,
  onTimeRangeChange,
}: ShapeTable
) {


  const table = useReactTable({
    columns,
    defaultColumn,
    data,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      sorting,
    },
    onPaginationChange: () => {
      onPaginationChange(pagination);
    },
    pageCount: Math.ceil(totalItems / pagination.pageSize), // Calculate total pages
    onSortingChange: onSortingChange,
  });

  const filterMenuRef = useRef<HTMLDivElement>(null);

  // Hacks full-width on table management view when on desktop
  useEffect(() => {
    const paths = ['adset-management', 'ad-management', 'campaign-management','optimization-report','approval-page'];
    if (paths.some(path => location.pathname.includes(`/view/${path}`))) {
      const elements = document.querySelectorAll('.titan_DefaultLayout_main, .titan_DefaultLayout_mainDark');
      elements.forEach(element => {
        (element as HTMLElement).style.maxWidth = 'none';
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterMenuRef.current &&
        event.target instanceof Node &&
        !filterMenuRef.current.contains(event.target)
      ) {
        setClickOutSide(!clickOutSide);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const [clickOutSide, setClickOutSide] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  
  // get brand accounts
  const accountsResource = initialAccounts;
  const {curAccount} = useContext(DashboardContext);

  // quick filter by status and brand account
  const quickFilterStatus = quickFilter && quickFilter?.find((filter) => filter.property === 'status') as QuickFilter<boolean>;
  const quickFilterBrand = quickFilter && quickFilter?.find((filter) => filter.property === 'brandAccount') as QuickFilter<string>;

  // useEffect(() => {
  //   setIsChecked(!isChecked);
  //   quickFilterStatus.onChange(isChecked);
  // },[]);
  // handle search, we need to state to keep search value when close the popover
  // and then pass the value to parent component

  // This trigger brandSelector dropdown in all table management
  useEffect(() => {
    quickFilterBrand?.onChange(curAccount?.name);
  }, [curAccount]);

  
  const [search, setSearch] = useState('');
  const handleSearchChange = (value: string) => {
    setSearch(value);
    onSearchChange(value);
  }
  const [isChecked, setIsChecked] = useState(true);

  return (
    <>
      <div className={style.tableWrapper}>
        <div className={style.action}>
          <div className={style.actionWrapper}>
            {/* added check to wait for data to load before rendering filter */}
            {table.getHeaderGroups().length > 0 && (
              <FilterComponent
                ref={filterMenuRef}
                data={table}
                onClose={() => setClickOutSide(false)}
                filterInstances={async (filters: any[]) => filterInstances(filters)}
                filterOff={(e) => resetFilter(e)}
                isLoading={handleLoading}
                onOpen={(value: boolean) => setClickOutSide(value)}
              />
            )}
            <div className={style.batchActionGroup}>
              {selectedRows.length > 0 && batchActionDropdown && (
                <>
                  <p>Batch Actions</p>
                  {React.cloneElement(
                    batchActionDropdown as React.ReactElement<any>,
                    { selectedRows },
                  )}
                </>
              )}
            </div>
          </div>

          <div className={style.additionalFilter}>
            <div className={style.actionWrapper}>
              {dateFilter && (
                <div className={style.batchActionGroup}>{dateFilter}</div>
              )}
              {timeRange && (
                <FilterTimeRange name="timerange" variant="outline" timeRange={timeRange} onHandleChange={onTimeRangeChange} />
              )}
              {quickFilter && quickFilter.length > 0 && (
                <div>
                  <Popover.Root>
                    <Popover.Trigger asChild>
                      <button 
                        className={cl(style.quickFilterBtn, quickFilterStatus?.value || quickFilterBrand?.value ? style.isActive : '')} 
                        aria-label='quick filter'
                      >
                        <Icons.SliderHorizontal width={16} height={16} />
                      </button>
                    </Popover.Trigger>
                    <Popover.Content align='end' hideClose>
                      <div className={style.quickFilterWrapper}>
                        {quickFilterBrand && (
                          <>
                          <h4>Brand Account:</h4>
                          <BrandAccountSelector 
                            className={style.brandAccountSelector}
                            showLabel={false} 
                            qAccounts={accountsResource} 
                          />
                          </>
                        )}
                        {quickFilterStatus && (
                            <div className={style.activeCheckbox}>
                            <label htmlFor="StatusFilter">
                              {quickFilterStatus.label}
                            </label>
                            <label className={style.switch}>
                              <input
                              type="checkbox"
                              id="StatusFilter"
                              onChange={(e) => {
                                setIsChecked(e.target.checked);
                                quickFilterStatus.onChange(e.target.checked);
                              }}
                              checked={isChecked}
                              />
                              <span className={style.slider}></span>
                            </label>
                            </div>
                        )}
                      </div>
                      <Popover.Arrow />
                    </Popover.Content>
                  </Popover.Root>
                </div>
                
              )}
                <div className={style.searchBox}>
                      <input
                        type="text"
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => handleSearchChange(e.target.value)}
                      />
                    </div>
              <div>
               
                    <button
                      onClick={() => {
                        const searchBox = document.querySelector(`.${style.searchBox}`);
                        if (searchBox) {
                          const isDisplayed = (searchBox as HTMLElement).style.display === 'block';
                          (searchBox as HTMLElement).style.display = isDisplayed ? 'none' : 'block';
                        }
                      }}
                      className={cl(style.searchBtn, search ? style.isActive : '')}
                      aria-label='search'
                    >
                      <Icons.Search width={16} height={16} />
                    </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={cl(
            style.tableContainer,
            pagination.pageSize >= 20 && style.expanded,
          )}
        >
          <div className={style.overlaySpinner}>
            <Spinner active={loading} />
            {loading && <p>Loading</p>}
          </div>
          <table className={cl(style.table)}>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className={isLight ? style.tr : style.trDark}
                >
                  <th
                    className={cl(
                      isLight ? style.th : style.thDark,
                      style.sticky,
                      style.stickyLeft,
                    )}
                  >
                    <input type="checkbox" onChange={toggleSelectAll} />
                  </th>
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={`${isLight ? style.th : style.thDark} ${header.column.columnDef.id === 'status' ? style.stickySecondRow : (header.column.columnDef.id === 'shortAdName' ? style.stickyThirdRow : '')} ${style.sticky}`}
                    >
                      {index === 0 ? (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                      ) : (
                        <div
                          {...{
                          className: header.column.getCanSort()
                          ? style.canSort
                          : '',
                          onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                          typeof header.column.columnDef.header === 'string'
                          ? header.column.columnDef.header
                            .replace('$', '')
                            .replace(/(cpc|cvr|epc|ctr)/gi, (match) => match.toUpperCase())
                          : header.column.columnDef.header,
                          header.getContext(),
                          )}
                          {{
                          asc: <Icons.UpArrow />,
                          desc: <Icons.DownArrow />,
                          }[header.column.getIsSorted() as string] ?? (
                          <Icons.LeftRightArrow />
                          )}
                        </div>
                      )}
                    </th>
                  ))}
                  {/* Conditionally render the right-side fixed header column */}
                  {onDetailsClick && (
                    <th
                      className={cl(
                        isLight ? style.th : style.thDark,
                        style.sticky,
                        style.stickyRight,
                      )}
                    >
                      Actions
                    </th>
                  )}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  data-id={row.original.id}
                  className={isLight ? style.tr : style.trDark}
                >
                  <td
                    className={cl(
                      isLight ? style.td : style.tdDark,
                      style.sticky,
                      style.stickyLeft,
                    )}
                  >
                   
                    {isLoading ? (
                      <div className={style.skeletonCell}></div>
                    ) : (
                      <input
                        type="checkbox"
                        onChange={() => toggleRowSelection(row.original.id)}
                        checked={isRowSelected(row.original.id)}
                      />
                    )}
                   
                  </td>
                  { row.getVisibleCells().map((cell) => {
                    //see if there is any function provided for this column to dynamically determine an extra className
                    const dynamicClassNameFn = cell.column.columnDef.meta ? cell.column.columnDef.meta['className'] : null;
                    let dynamicClassName = '';
                    if (dynamicClassNameFn)
                    {
                      //if so, send the cell value to it
                      dynamicClassName = dynamicClassNameFn(cell.renderValue(),cell.row.original);
                    }

                    return (<td
                        key={cell.id}
                        className={cl(
                          isLight ? style.td : style.tdDark,
                          cell.column.columnDef.id === 'status' ? style.stickySecondRow : cell.column.columnDef.id === 'shortAdName' ? style.stickyThirdRow : '', style.sticky,
                          dynamicClassName
                        )}
                        onMouseEnter={() => {
                          if(cell.column.columnDef.id === 'shortAdName') {
                            [...document.getElementsByClassName('titan_ShapeTable_stickyThirdRow')].forEach(p => {
                              ((p as HTMLTableCellElement).children[0] as HTMLDivElement).style.width = '300px';
                            });
                          }
                        }}
                        onMouseLeave={() => {
                          if(cell.column.columnDef.id === 'shortAdName') {
                            [...document.getElementsByClassName('titan_ShapeTable_stickyThirdRow')].forEach(p => {
                              ((p as HTMLTableCellElement).children[0] as HTMLDivElement).style.width = '';
                            });
                          }
                        }}
                      >
                        {isLoading ? (
                          <div className={style.skeletonCell}></div>
                        ) : <div>{(flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()))}</div>
                        }
                      </td>
                    )
                  })}
                  {/* Conditionally render the right-side fixed cell */}
                  {onDetailsClick && (
                    <td
                      className={cl(
                        isLight ? style.td : style.tdDark,
                        style.sticky,
                        style.stickyRight,
                      )}
                    >
                      {isLoading ? (
                        <div className={style.skeletonCell}></div>
                      ) : (
                        <button
                          onClick={() => onDetailsClick(row.original)}
                          className={style.actionButton}
                        >
                          <Icons.Eye />
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={style.pagination}>
          <span className={style.pageInfo}>
            Page {pagination.pageIndex + 1} of{' '}
            <strong>{Math.ceil(totalItems / pagination.pageSize)}</strong>
          </span>
          <span className={style.pageInfo}>
            <input
              type="number"
              className={style.goToPageForm}
              value={pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                onPaginationChange({ ...pagination, pageIndex: page });
              }}
            />
          </span>
          <select
            className={style.selectPageRows}
            value={pagination.pageSize}
            onChange={(e) => {
              onPaginationChange({
                ...pagination,
                pageSize: Number(e.target.value),
              });
            }}
          >
            {[10, 20, 50, 100].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
          <button
            className={style.paginationButton}
            onClick={() => onPaginationChange({ ...pagination, pageIndex: 0 })}
            disabled={pagination.pageIndex === 0}
          >
            <Icons.DoubleLeftArrow />
          </button>
          <button
            className={style.paginationButton}
            onClick={() =>
              onPaginationChange({
                ...pagination,
                pageIndex: pagination.pageIndex - 1,
              })
            }
            disabled={pagination.pageIndex === 0}
          >
            <Icons.SingleLeftArrow />
          </button>
          <button
            className={style.paginationButton}
            onClick={() =>
              onPaginationChange({
                ...pagination,
                pageIndex: pagination.pageIndex + 1,
              })
            }
            disabled={
              pagination.pageIndex >=
              Math.ceil(totalItems / pagination.pageSize) - 1
            }
          >
            <Icons.SingleRightArrow />
          </button>
          <button
            className={style.paginationButton}
            onClick={() =>
              onPaginationChange({
                ...pagination,
                pageIndex: Math.ceil(totalItems / pagination.pageSize) - 1,
              })
            }
            disabled={
              pagination.pageIndex >=
              Math.ceil(totalItems / pagination.pageSize) - 1
            }
          >
            <Icons.DoubleRightArrow />
          </button>
        </div>
      </div>
    </>
  );

}

export default ShapeTable;
