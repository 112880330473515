import React, {useMemo} from 'react'
import { DynamicObject,  } from '../utils';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, GridComponent, MarkAreaComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { TimeRangeProps } from '../types';
import { FilterTimeRange } from './FilterTimeRange';
import { Select } from './Select';
import './AdChart.scss';
import style from './AdChart.scss.json';

// https://github.com/hustcc/echarts-for-react?tab=readme-ov-file#usage
// import ECharts.js modules manually to reduce bundle size
echarts.use([TitleComponent, TooltipComponent, GridComponent, LineChart, CanvasRenderer, MarkAreaComponent]);

// add UTC plugin to dayjs to handle UTC date
// this make sure use correct date for x-axis in the bidAmount chart
dayjs.extend(utc);

interface AdChartProps {
    data: DynamicObject;
    metric: string; // filter by engagement metric
    onMetricChange?: (metric: string) => void;
    timeRange: TimeRangeProps;
    onTimeRangeChange?: (timeRange: TimeRangeProps) => void;
    showBidAmount?: boolean;
  }
  export function AdChart({data, metric, timeRange, onMetricChange, onTimeRangeChange}: AdChartProps) {
    const defaultChartOption = {
      grid: {
        top: '50px',
        bottom: '30px',
        right: '25px',
        left: '50px',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
    }
  
    const engagementChartOptions = useMemo(() => {
      const markAreas = [];
      let start = null;
  
      const xAxisData = data?.[metric]?.map((obs) => dayjs(obs.date).format('MMM D')) || [];
      const xAxisValue = data?.[metric]?.map(obs => parseFloat(obs.value)) || [];
  
      // find the start and end of the `0` value.
      // when the value is `0`, it means the ad is paused
      // we use this to mark the area in the chart
      data?.[metric]?.forEach((obs, index) => {
        const value = parseFloat(obs.value); // convert value to number
        if (value === 0 && start === null) {
          start = index;
        } else if (value !== 0 && start !== null) {
          markAreas.push([{ xAxis: xAxisData[start] }, { xAxis: xAxisData[index - 1] }]);
          start = null;
        }
      });
  
      if (start !== null) {
        markAreas.push([{ xAxis: xAxisData[start] }, { xAxis: xAxisData[data[metric].length - 1] }]);
      }
  
      return {
        ...defaultChartOption,
        title: {
          text: 'Engagement Metric',
          textStyle: {
            fontSize: 15
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: metric,
            type: 'line',
            smooth: true,
            data: xAxisValue,
            markArea: {
              itemStyle: {
                color: 'rgba(156, 180, 184, 0.4)'
              },
              data: markAreas
            }
          }
        ]
      };
    }, [metric, data]);
  
    const bidAmountChartOptions = useMemo(() => {
      const markAreas = [];
      let start = null;
    
      const xAxisData = data?.bidAmount?.map((obs) => {
        return dayjs(obs.date).utc().format('YYYY-MM-DD HH:mm:ss');
      }) || [];
    
      // find the start and end of the `0` value.
      // when the value is `0`, it means the ad is paused
      // we use this to mark the area in the chart
      data?.bidAmount?.forEach((obs, index) => {
        const value = parseFloat(obs.value); // convert value to number
        if (value === 0 && start === null) {
          start = index;
        } else if (value !== 0 && start !== null) {
          markAreas.push([{ xAxis: xAxisData[start] }, { xAxis: xAxisData[index - 1] }]);
          start = null;
        }
      });
    
      if (start !== null) {
        markAreas.push([{ xAxis: xAxisData[start] }, { xAxis: xAxisData[data.bidAmount.length - 1] }]);
      }
  
      // calculate the time range in days
      const timeRangeDays = dayjs(timeRange.endDate).diff(dayjs(timeRange.startDate), 'day') + 1;
    
      return {
        ...defaultChartOption,
        title: {
          text: 'Bid Amount',
          textStyle: {
            fontSize: 15
          }
        },
        xAxis: {
          type: 'time',
          boundaryGap: false,
          // min: dayjs(timeRange.startDate).startOf('day').toDate(),
          // max: dayjs(timeRange.endDate).endOf('day').toDate(),
          // split the x-axis into 2 parts if the time range is more than 20 days
          // because if not the label is too close
          splitNumber: timeRangeDays > 20 ? timeRangeDays / 2 : timeRangeDays,  
          axisLabel: {
            formatter: function (value) {
              return dayjs(value).format('MMM D');
            },
            interval: 'auto' 
          },
          axisTick: {
            interval: 'auto' 
          },
          splitLine: {
            show: true,
            interval: 'auto',
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Bid Amount',
            type: 'line',
            step: 'end',
            data: data?.bidAmount?.map(obs => [dayjs(obs.date).utc().format('YYYY-MM-DD HH:mm:ss'), parseFloat(obs.value)]) || [],
            markArea: {
              itemStyle: {
                color: 'rgba(156, 180, 184, 0.4)'
              },
              data: markAreas
            }
          }
        ],
      };
    }, [data]);

    // check if the data has bidAmount, if not don't show the bidAmount chart
    const hasBidAmount = data && data.bidAmount && data.bidAmount.length > 0;
  
    return (
      <div>
        <div className={style.chartCard}>
          <div className={style.chartFilter}>
            <label>
              Filter by
              <Select value={metric} onChange={(e) => onMetricChange(e.target.value)} style={{marginLeft: '.5rem'}}>
                <option value="dailyImpressions">Impressions</option>
                <option value="dailyClicks">Clicks</option>
                <option value="dailySpend">Spend</option>
              </Select>
            </label>
            <FilterTimeRange 
              name="metric" 
              label="Time Range" 
              timeRange={timeRange} 
              onHandleChange={(newTime: TimeRangeProps) => onTimeRangeChange(newTime)}
            />
          </div>
          <div>
            <ReactEChartsCore
              echarts={echarts}
              option={engagementChartOptions}
              notMerge={true}
              lazyUpdate={true}
              theme={'light'}
              style={{ maxWidth: '100%'}}
            />
          </div>
        </div>
        {hasBidAmount && (
          <div className={style.chartCard}>
            <div>
              <ReactEChartsCore
                echarts={echarts}
                option={bidAmountChartOptions}
                notMerge={true}
                lazyUpdate={true}
                theme={'light'}
                style={{ maxWidth: '100%'}}
              />
            </div>
          </div>
        )}
      </div>
    )
  }