import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of the context
// interface RowContextType {
//   rowData: RowData | null;
//   setRowData: (data: RowData | null) => void;
// }

// Create the context with the defined type
const RowContext = createContext<any | undefined>(undefined);

// Custom hook to use the context
export const useRowContext = () => {
  const context = useContext(RowContext);
  if (!context) {
    throw new Error("useRowContext must be used within a RowProvider");
  }
  return context;
};

// The provider component
export const RowProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [rowData, setRowData] = useState<any>(null);

  return (
    <RowContext.Provider value={{ rowData, setRowData }}>
      {children}
    </RowContext.Provider>
  );
};
