import React from 'react'
import './Modal.scss';
import style from './Modal.scss.json';
import { cl } from 'lincd/lib/utils/ClassNames';

interface ModalProps {
  show: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ show, onClose, title, children }) => {
  if (!show) return null;

  return (
    <div className={cl(style.modalOverlay)} onClick={onClose}>
      <div className={style.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={style.modalHeader}>
          <h2>{title}</h2>
          <button className={style.closeButton} onClick={onClose}>
            &times;
          </button>
        </div>
        <div className={style.modalBody}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
