import React, { useCallback, useState, useContext, useEffect } from 'react';
import { Ad } from 'titan-ads/lib/shapes/TitanShapes';
import { formatDate, calculatePercentage } from '../utils/data';
import '../components/RefreshQuoraData.scss';
import '../pages/MediaMixReport.scss';
import { default as style } from '../pages/MediaMixReport.scss.json';
import { DashboardContext } from '../contexts/Dashboard';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../package';

export interface SalesByDeviceProps {
    deviceType: string;
    data: {
        id: string;
        ads: Ad[];
        conversions: any[] | null;
        Sales: number;
        Clicks: number;
        SalesPercent: string;
        ClicksPercent: string;
    }[];
}

export interface LoadingState {
    success: boolean;
    message: string;
    state: boolean;
}

export interface ShowHideColumn {
    label: string;
    value: string;
    isChecked: boolean;
}
// Constants for conversion types and endpoints
const CONVERSION_TYPE = 'conversions';
const CLICK_REPORT = 'clicks';
const FIELD_PARAMS_CONVERSION = 'subid_1,offer_name,conversion_date,';
const FIELD_PARAMS_CLICKS = 'subid_1,click_date';
const CAMPAIGN_ID = 2484; // Default campaign ID
export const TableHeader = [
    { label: 'deviceType', value: 'Device Type', },
    { label: 'headline', value: 'Headline Description', },
    { label: 'identifier', value: 'Identifier', },
    { label: 'dates', value: 'Date', },
    { label: 'clicks', value: 'Clicks', },
    { label: 'click-percentage', value: 'Clicks %', },
    { label: 'sales', value: 'Sales', },
    { label: 'sales-percentage', value: 'Sales %', },
    { label: 'total-sales', value: 'Total Sales', },
    { label: 'total-sales-percentage', value: 'Total Sales %', },
    { label: 'total-clicks', value: 'Total Clicks', },
    { label: 'total-clikcs-percentage', value: 'Total Clicks %', }
];

export default function SalesByDevice({
    StartDate,
    CakeData,
    onLoading,
    showFullReport,
    columnValue,
}: {
    StartDate: { dateValue: string; customDate: boolean };
    CakeData: (data: any) => void;
    onLoading: (loadingState: LoadingState) => void;
    showFullReport: boolean;
    columnValue: ShowHideColumn[];
}) {
    // State for the media mix data
    const [MediaMixData, setMediaMixData] = useState<SalesByDeviceProps[]>([]);
    const [isLoading, setIsLoading] = useState<LoadingState>({ success: false, message: '', state: false });
    const { curAccount } = useContext(DashboardContext);
    const [OriginalData, setOriginalData] = useState<SalesByDeviceProps[]>([]);

    // Reset data when custom date changes
    useEffect(() => {
        if (StartDate.customDate) {
            resetData();
        }
    }, [StartDate]);

    // Reset data
    const resetData = () => {
        setMediaMixData([]);
        CakeData([]);
        setIsLoading({ success: false, message: '', state: false });
        onLoading({ success: false, message: '', state: false });
    };

    const salesByDevice = useCallback(async () => {
        // UX Enhancement: Skeleton Loader
        setIsLoading({ success: false, message: 'Retrieving data...', state: true });
        onLoading({ success: false, message: 'Retrieving data...', state: true });

        // Get the current date for custom date (cannot be changed - cannot set for future dates)
        const endDate = new Date().toISOString().split('T')[0];

        // Fetch data from Cake
        try {
            const response = await Server.call(packageName, 'fetchMediaMixData', {
                StartDate,
                endDate,
                CONVERSION_TYPE,
                CLICK_REPORT,
                FIELD_PARAMS_CONVERSION,
                FIELD_PARAMS_CLICKS,
                CAMPAIGN_ID,
                curAccount
            });

            // Check if the current account matches the returned data from Cake
            if (response && curAccount?.offer !== response?.conversions.data[0].offer_name.replace(/\s+/g, '')) {
                alert('The current account does not match the returned data from Cake. Please select the correct account.');
                resetData();
                return;
            }
            console.log('MediaMix', response)
            // Set the data for the CakeData component, retrived from the Cake API
            // since its JSON object convert into a Map object so no changes need on previous structure of SalesHeadline
            CakeData({
                conversionsByUniqueId: new Map<string, any[]>(Object.entries(response?.conversionsByUniqueIdObj)),
                adsByUniqueId: new Map<string, Ad[]>(Object.entries(response?.adsByUniqueIdObj)),
                clicks: response?.clicks,
            });

            // Set the data for the MediaMixData component
            setMediaMixData(response?.MediaMix as SalesByDeviceProps[]);
            setOriginalData(response?.MediaMix as SalesByDeviceProps[]);
            setIsLoading({ success: true, message: 'Data retrieved successfully.', state: false });
            onLoading({ success: true, message: 'Data retrieved successfully.', state: false });
        } catch (error) {
            console.error('Error fetching media mix data:', error);
            resetData();
            setIsLoading({ success: false, message: 'Failed to retrieve data.', state: false });
            onLoading({ success: false, message: 'Failed to retrieve data.', state: false });
        }
    }, [StartDate, curAccount]);

    // Filtering for display only, based on `columnValue`
    const displayData = MediaMixData.filter(entry =>
        columnValue.some(column => column.value === entry.deviceType)
    );

    // Calculate original totals and original percentages
    const originalTotals = OriginalData.reduce(
        (acc, entries) => {
            const totalSales = entries.data.reduce((sum, entry) => sum + entry.Sales, 0);
            const totalClicks = entries.data.reduce((sum, entry) => sum + entry.Clicks, 0);

            if (entries.deviceType === 'Mobile') {
                acc.mobileSales += totalSales;
                acc.mobileClicks += totalClicks;
            } else if (entries.deviceType === 'Desktop') {
                acc.desktopSales += totalSales;
                acc.desktopClicks += totalClicks;
            } else if (entries.deviceType === 'Email') {
                acc.emailSales += totalSales;
                acc.emailClicks += totalClicks;
            }
            return acc;
        },
        { mobileSales: 0, mobileClicks: 0, desktopSales: 0, desktopClicks: 0, emailSales: 0, emailClicks: 0 }
    );

    // Store original totals for calculation of percentages
    const originalCombinedSales = originalTotals.mobileSales + originalTotals.desktopSales + originalTotals.emailSales;
    const originalCombinedClicks = originalTotals.mobileClicks + originalTotals.desktopClicks + originalTotals.emailClicks;

    // Calculate totals based on filtered data for display
    const filteredTotals = displayData.reduce(
        (acc, entries) => {
            const totalSales = entries.data.reduce((sum, entry) => sum + entry.Sales, 0);
            const totalClicks = entries.data.reduce((sum, entry) => sum + entry.Clicks, 0);

            if (entries.deviceType === 'Mobile') {
                acc.mobileSales += totalSales;
                acc.mobileClicks += totalClicks;
            } else if (entries.deviceType === 'Desktop') {
                acc.desktopSales += totalSales;
                acc.desktopClicks += totalClicks;
            } else if (entries.deviceType === 'Email') {
                acc.emailSales += totalSales;
                acc.emailClicks += totalClicks;
            }
            return acc;
        },
        { mobileSales: 0, mobileClicks: 0, desktopSales: 0, desktopClicks: 0, emailSales: 0, emailClicks: 0 }
    );

    // Calculate total clicks and sales for current filtered data
    const combinedFilteredSales = filteredTotals.mobileSales + filteredTotals.desktopSales + filteredTotals.emailSales;
    const combinedFilteredClicks = filteredTotals.mobileClicks + filteredTotals.desktopClicks + filteredTotals.emailClicks;

    // Calculate and display the percentages based on original totals
    const mobileClicksPercentage = originalCombinedClicks > 0 ? (filteredTotals.mobileClicks / originalCombinedClicks * 100).toFixed(2) : '0.00%';
    const mobileSalesPercentage = originalCombinedSales > 0 ? (filteredTotals.mobileSales / originalCombinedSales * 100).toFixed(2) : '0.00%';
    const desktopClicksPercentage = originalCombinedClicks > 0 ? (filteredTotals.desktopClicks / originalCombinedClicks * 100).toFixed(2) : '0.00%';
    const desktopSalesPercentage = originalCombinedSales > 0 ? (filteredTotals.desktopSales / originalCombinedSales * 100).toFixed(2) : '0.00%';
    const emailClicksPercentage = originalCombinedClicks > 0 ? (filteredTotals.emailClicks / originalCombinedClicks * 100).toFixed(2) : '0.00%';
    const emailSalesPercentage = originalCombinedSales > 0 ? (filteredTotals.emailSales / originalCombinedSales * 100).toFixed(2) : '0.00%';

    // function to calculate percentage based on total values
    const calculateIndividualPercentage = (value: number, total: number) =>
        total > 0 ? ((value / total) * 100).toFixed(2) : '0.00';


    useEffect(() => {
        const filteredMediaMixData = OriginalData.filter(entry =>
            columnValue.some(column => column.value === entry.deviceType)
        );
        setMediaMixData(filteredMediaMixData);
    }, [columnValue, OriginalData]);

    const showMobileColumn = columnValue.some(column => column.value === 'Mobile');
    const showDesktopColumn = columnValue.some(column => column.value === 'Desktop');
    const showEmailColumn = columnValue.some(column => column.value === 'Email');

    const Wrap = showFullReport ? 'strong' : React.Fragment;
    return (
        <>
            <div className={style.buttonContainer}>
                <button
                    disabled={StartDate.dateValue === '' || isLoading.state}
                    className={style.buttonGeneric}
                    onClick={salesByDevice}
                >
                    {isLoading.state ? 'Collecting Data...' : 'Generate Data'}
                </button>
            </div>
            <table className={style.mediaMixData}>
                <thead>
                    <tr>
                        <th style={{ width: '20%' }}>Device</th>
                        {showFullReport && (
                            <th>Identifier</th>
                        )}
                        <th>Date Range</th>
                        <th>Clicks</th>
                        <th>Clicks %</th>
                        <th>Sales</th>
                        <th>Sales %</th>
                        <th>1st Rebill Rate %</th>
                        {/*{TableHeader.filter(option =>*/}
                        {/*  columnValue.some(column => column.value === option.value) &&*/}
                        {/*  !['Total Sales','Total Sales %','Total Clicks','Total Clicks %'].includes(option.value),*/}
                        {/*).map((option) =>*/}
                        {/*    option.value !== 'Headline Description' && (*/}
                        {/*      <th key={option.value} colSpan={option.value === 'Identifier' ? 2 : 1}>*/}
                        {/*        {option.value}*/}
                        {/*      </th>*/}
                        {/*    ),*/}
                        {/*)}*/}
                    </tr>
                </thead>
                <tbody>
                    {showFullReport && (displayData.length > 0 ? (
                        displayData.map((entries, index) => (
                            <React.Fragment key={index}>
                                {entries.data.map((entry, entryIndex) => (
                                    <tr key={entryIndex}>
                                        {entryIndex === 0 && (
                                            <td rowSpan={entries.data.length}>
                                                <p>{entries.deviceType.split(',')[0]}</p>
                                            </td>
                                        )}
                                        <td><p>{entry.conversions[0]?.subid_1}</p></td>
                                        <td><p>{`${formatDate(StartDate.dateValue)} - ${formatDate()}`}</p></td>
                                        <td><p>{entry.Clicks}</p></td>
                                        <td>
                                            <p>{calculateIndividualPercentage(entry.Clicks, originalCombinedClicks)}%</p>
                                        </td>
                                        <td><p>{entry.Sales}</p></td>
                                        <td>
                                            <p>{calculateIndividualPercentage(entry.Sales, originalCombinedSales)}%</p>
                                        </td>
                                        <td></td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={8} style={{ textAlign: 'center' }}>
                                <p style={{ fontSize: '16px' }}>Currently, there is no data to display. Please generate data.</p>
                            </td>
                        </tr>
                    ))}
                    {showMobileColumn && (
                        <tr className={style.totalTable}>
                            <td colSpan={showFullReport ? 3 : 1}>{showFullReport ? <Wrap><p>Total for Mobile</p></Wrap> : <p>Mobile</p>}</td>
                            {!showFullReport && <td><p>{`${formatDate(StartDate.dateValue)} - ${formatDate()}`}</p></td>}
                            <td><Wrap><p>{filteredTotals.mobileClicks}</p></Wrap></td>
                            <td><Wrap><p>{mobileClicksPercentage}%</p></Wrap></td>
                            <td><Wrap><p>{filteredTotals.mobileSales}</p></Wrap></td>
                            <td><Wrap><p>{mobileSalesPercentage}%</p></Wrap></td>
                            <td rowSpan={3}><Wrap><p>55.3%</p></Wrap></td>
                        </tr>
                    )}
                    {showDesktopColumn && (
                        <tr>
                            <td colSpan={showFullReport ? 3 : 1}>{showFullReport ? <Wrap><p>Total for Desktop</p></Wrap> : <p>Desktop</p>}</td>
                            {!showFullReport && <td><p>{`${formatDate(StartDate.dateValue)} - ${formatDate()}`}</p></td>}
                            <td><Wrap><p>{filteredTotals.desktopClicks}</p></Wrap></td>
                            <td><Wrap><p>{desktopClicksPercentage}%</p></Wrap></td>
                            <td><Wrap><p>{filteredTotals.desktopSales}</p></Wrap></td>
                            <td><Wrap><p>{desktopSalesPercentage}%</p></Wrap></td>
                        </tr>
                    )}
                    {showEmailColumn && (
                        <tr>
                            <td colSpan={showFullReport ? 3 : 1}>{showFullReport ? <Wrap><p>Total for Email</p></Wrap> : <p>Email</p>}</td>
                            {!showFullReport && <td><p>{`${formatDate(StartDate.dateValue)} - ${formatDate()}`}</p></td>}
                            <td><Wrap><p>{filteredTotals.emailClicks}</p></Wrap></td>
                            <td><Wrap><p>{emailClicksPercentage}%</p></Wrap></td>
                            <td><Wrap><p>{filteredTotals.emailSales}</p></Wrap></td>
                            <td><Wrap><p>{emailSalesPercentage}%</p></Wrap></td>
                        </tr>
                    )}
                    <tr>
                        <td colSpan={showFullReport ? 3 : 2}><strong>{showFullReport ? "Overall Total" : "Total"}</strong></td>
                        <td><strong>{combinedFilteredClicks}</strong></td>
                        <td><strong>{calculatePercentage(combinedFilteredClicks, originalCombinedClicks)}%</strong></td>
                        <td><strong>{combinedFilteredSales}</strong></td>
                        <td><strong>{calculatePercentage(combinedFilteredSales, originalCombinedSales)}%</strong></td>
                        <td><strong>55.3%</strong></td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

