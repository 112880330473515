import React, { useState, useEffect, forwardRef } from 'react';
import './ShapeTable.scss';
import ReactSelect from 'react-select';
import style from './ShapeTable.scss.json';
import { Icons } from './Icons';
import { cl } from 'lincd/lib/utils/ClassNames';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

// Structure of a menu item
interface MenuItem {
    name: string;
    checked?: boolean;
    value?: string;
    condition?: string;
    subItems?: MenuItem[];
}

// Props for the FilterComponent
interface FilterComponentProps {
    data: any;
    onClose: () => void;
    filterInstances: (filters: any[]) => Promise<void>;
    filterOff: (status: boolean) => void;
    isLoading: (status: boolean) => void;
    onOpen?: (status: boolean) => void;
}

// Initial menu items
const initialMenuItems: MenuItem[] = [
    { name: 'Ads', value: '', condition: '', subItems: [] },
];

// Component for the filter menu
const FilterMenu = ({ menuItems, onCheckboxChange, searchResults, onSearchChange, activeFilters }) => {
    const [checkboxValue, setCheckboxValue] = useState(false);

    return (
        <nav className={style.menu}>
            <div className={style.searchContainer}>
                <input
                    type="text"
                    placeholder="Search attributes..."
                    className={style.searchInput}
                    onChange={(e) => onSearchChange(e.target.value)}
                    style={{
                        backgroundImage: `url(${asset('/images/glass.png')})`,
                        backgroundColor: '#fafafa',
                        backgroundRepeat: 'no-repeat',
                        paddingLeft: '35px',
                        backgroundSize: '28px',
                        backgroundPosition: '5px',
                        position: 'relative',
                        top: '5px',
                        left: '2px',
                        borderWidth: '1px !important',
                        borderColor: '#c1c1c1 !important',
                        borderStyle: 'solid !important',
                    }}
                />
            </div>
            <ul>
                {searchResults
                    .filter((item) => item.subItems.length > 0)
                    .map((item) => (
                        <li key={item.name} className={style.menuList}>
                            {item.subItems &&
                                item.subItems.map((subItem) => (
                                    <div
                                        key={subItem.name}
                                        className={style.subMenuItem}
                                        onClick={() => {
                                            const isChecked = !subItem.checked;
                                            setCheckboxValue(isChecked);
                                            onCheckboxChange(item.name, subItem.name, isChecked);
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={
                                                activeFilters.length > 0
                                                    ? activeFilters.some((filter) => filter.name === subItem.name)
                                                    : subItem.checked
                                            }
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                setCheckboxValue(e.target.checked);
                                                onCheckboxChange(item.name, subItem.name, e.target.checked);
                                            }}
                                        />
                                        <label>{subItem.name}</label>
                                    </div>
                                ))}
                        </li>
                    ))}
            </ul>
        </nav>
    );
};

// Component for the filter selection
const FilterSelection = ({
    menuItems,
    availableOptions,
    conditionOptions,
    onSelectChange,
    onInputChange,
    onRemoveFilter,
    activeFilters,
    filterConditions,
    filterValues,
}) => {
    return (
        <>
            {menuItems
                .flatMap((item) =>
                    item.subItems
                        ?.filter((subItem) => subItem.checked)
                        .map((subItem, itemIndex) => ({
                            parent: item,
                            subItem,
                            itemKey: `${subItem.name}-${itemIndex}`,
                        }))
                )
                .map(({ parent, subItem, itemKey }, itemIndex) => {
                    const selectedNames = menuItems
                        .flatMap((item) => item.subItems)
                        .filter((subItem) => subItem.checked)
                        .map((subItem) => subItem.name);
                    
                    function formatOption(name: string): string {
                        return name
                            .replace(/([a-z])([A-Z])/g, '$1 $2')
                            .replace(/_/g, ' ')
                            .replace(/\b\w/g, (char) => char.toUpperCase());
                    }

                    return (
                        <React.Fragment key={`${subItem.name}-${itemIndex}`}>
                            <div className={style.filterSelection}>
                                <img src={asset('/images/menu.png')} alt="menu-filter" className={style.menuFilterIcons} />
                                <p>Where</p>
                                <div className={style.reactSelectInput}>
                                    <ReactSelect
                                        options={availableOptions
                                            ?.filter((option) => !selectedNames.includes(option.value))
                                            .map((option) => ({
                                                label: option.label,
                                                value: option.value,
                                            }))}
                                        value={{
                                            label: subItem.name ? formatOption(subItem.name) : 'Select Attribute',
                                            value: subItem.name || '',
                                        }}
                                        onChange={(selectedOption) =>
                                            selectedOption.value !== subItem.name &&
                                            onSelectChange(selectedOption, subItem, 'name', activeFilters)
                                        }
                                        placeholder="Select Attribute"
                                        isSearchable={true}
                                    />
                                </div>
                                <div className={style.reactSelectInput}>
                                    <ReactSelect
                                        options={conditionOptions}
                                        value={conditionOptions.find((option) => option.value === (subItem.condition || filterConditions))}
                                        onChange={(selectedOption) =>
                                            onSelectChange(selectedOption, subItem, 'condition', filterConditions)
                                        }
                                        placeholder={'Add Condition'}
                                        isSearchable={false}
                                    />
                                </div>
                                <input
                                    type="text"
                                    placeholder="Enter value..."
                                    value={subItem.value || filterValues}
                                    aria-label="Filter Value"
                                    onChange={(e) => onInputChange(e.target.value, subItem)}
                                />
                                <img
                                    src={asset('/images/close.png')}
                                    alt="close-filter"
                                    className={style.menuFilterIcons}
                                    onClick={() => onRemoveFilter(subItem.name, itemIndex)}
                                />
                            </div>
                            <hr />
                        </React.Fragment>
                    );
                })}
        </>
    );
};

// Main FilterComponent
export const FilterComponent = forwardRef<HTMLDivElement, FilterComponentProps>(
    ({ data, onClose, filterInstances, filterOff, isLoading, onOpen }, ref) => {
        // State for menu items
        const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
        const [searchTerm, setSearchTerm] = useState('');
        const [activeFilters, setActiveFilters] = useState([]);
        const [isFilterMenuVisible, setIsFilterMenuVisible] = useState(false);
        const [isFilterPanelVisible, setIsFilterPanelVisible] = useState(false);
        const [isFilterInitialized, setIsFilterInitialized] = useState(false);
        const [searchResults, setSearchResults] = useState<MenuItem[]>([]);
        const [filterValues, setFilterValues] = useState([]);
        const [filterConditions, setFilterConditions] = useState([]);

        const conditionOptions = [
            { label: "Not Equal", value: "not_equal" },
            { label: "Smaller", value: "smaller" },
            { label: "Smaller or Equal", value: "smaller_or_equal" },
            { label: "Equals", value: "equal" },
            { label: "Greater", value: "greater" },
            { label: "Greater or Equal", value: "greater_or_equal" },
            { label: "Contains", value: "contains" },
        ];

        // Debounce logic for search
        const debounce = (func: (...args: any[]) => void, delay: number) => {
            let timeoutId: NodeJS.Timeout;
            return function (...args: any[]) {
                if (timeoutId) clearTimeout(timeoutId);
                timeoutId = setTimeout(() => func(...args), delay);
            };
        };

        // Temporary quick fix for filter issues
        useEffect(() => {
            clearAllFilters(null, false);
        }, []);

        // Reset menu items to original state
        const resetMenuItems = () => {
            setSearchResults(menuItems);
        };

        // Handle search filter based on query input
        const handleSearchChange = (query: string) => {
            setSearchTerm(query);

            if (!query.trim()) {
                resetMenuItems();
                return;
            }

            setSearchResults(
                menuItems.map((item) => ({
                    ...item,
                    subItems: item.subItems?.filter((subItem) => {
                        const formattedName = formatOption(subItem?.name).toLowerCase().replace(/\s+/g, ' ');
                        const queryTerms = query.toLowerCase().split(' ');
                        return queryTerms.every(term => formattedName.includes(term));
                    }),
                }))
            );
        };

        // formatOption for removing any spaces and special characters ($)
        const formatOption = (option: string): string => {
            return option
                ?.replace("$", "")
                ?.replace(/([a-z])([A-Z])/g, "$1 $2")
                ?.replace(/\b\w/g, (char) => char.toUpperCase());
        };

        // filter and sort available options 
        const availableOptions = menuItems
            .flatMap((item) => item?.subItems || [])
            .filter((subItem) => formatOption(subItem?.name).toLowerCase().includes(searchTerm.toLowerCase()))
            .sort((a, b) => formatOption(a.name).localeCompare(formatOption(b.name)))
            .map((subItem) => ({
                label: formatOption(subItem.name),
                value: subItem.name,
            }));


        // Handle checkbox changes for sub-items
        const handleCheckboxChange = (
            itemName: string,
            subItemName: string,
            isChecked: boolean,
        ) => {
            setMenuItems((prevItems) =>
                prevItems.map((item) => ({
                    ...item,
                    subItems: item.subItems?.map((subItem) =>
                        subItem.name === subItemName
                            ? { ...subItem, checked: isChecked }
                            : subItem,
                    ),
                })),
            );

            setSearchResults((prevItems) => {
                return prevItems.map((item) => ({
                    ...item,
                    subItems: item.subItems?.map((subItem) =>
                        subItem.name === subItemName
                            ? { ...subItem, checked: isChecked }
                            : subItem,
                    ),
                    checked: item.subItems?.some((subItem) => subItem.checked) || false,
                }));
            });

            setActiveFilters((prevItems) => {
                const updatedItems = prevItems.filter(
                    (item) => item.name !== subItemName,
                );
                if (isChecked) {
                    updatedItems.push({ name: subItemName, value: '', condition: '' });
                } else {
                    const existingItem = prevItems.find(
                        (item) => item.name === subItemName,
                    );
                    if (existingItem) {
                        updatedItems.push(existingItem);
                    }
                }
                return updatedItems;
            });
        };

        // Clears all filters
        const clearAllFilters = (e, updateData: boolean = true) => {
            setMenuItems((prevItems) =>
                prevItems.map((item) => ({
                    ...item,
                    subItems: item.subItems?.map((subItem) => ({
                        ...subItem,
                        checked: false,
                    })),
                })),
            );
            setActiveFilters([]);
            setIsFilterMenuVisible(false);
            setIsFilterInitialized(false);
            if (filterOff && updateData) {
                filterOff(true);
            }
        };

        // Removes a selected filter
        const removeSelectedFilter = async (
            itemName: string,
            index: number,
            fromWhere?: string,
        ) => {
            const updatedFilters = activeFilters.filter((_, i) => i !== index);

            setActiveFilters(updatedFilters);

            setMenuItems((prevItems) =>
                prevItems.map((item) => {
                    const updatedSubItems = item.subItems?.map((subItem, subIndex) => {
                        if (subItem.name === itemName && subIndex !== 0) {
                            return { ...subItem, checked: false };
                        }
                        if (subIndex === 0) {
                            return { ...subItem, checked: true, condition: '', value: '' };
                        }
                        return subItem;
                    });

                    if (item.subItems && item.subItems[0]?.name === itemName) {
                        setFilterConditions([]);
                        setFilterValues([]);
                    }

                    return { ...item, subItems: updatedSubItems };
                })
            );

            if (fromWhere === 'list-filter') {
                if (updatedFilters.length > 0) {
                    isLoading(true);
                    if (filterInstances) await filterInstances(updatedFilters);
                    isLoading(false);
                } else {
                    clearAllFilters(null, true);
                }
            }
        };

        // Handle apply filter logic with validation
        const applyFilters = async (data?: any) => {
            const filters = data?.length ? data : activeFilters;
            if (filters.some((item: any) => !item.condition || !item.value)) {
                alert('Please select the condition and enter the value for all filters.');
                return;
            }

            const formattedFilters = filters.map((item: any) => ({
                field: item.name.replace(/[\s$]+/g, ''),
                value: item.value,
                operator: item.condition,
            }));

            if (formattedFilters.length) {
                isLoading(true);
                setIsFilterPanelVisible(false);
                if (filterInstances) await filterInstances(formattedFilters);
                isLoading(false);
            }
        };

        // Effect for setting menu items and additional dynamic attributes (adItems)
        useEffect(() => {
            if (isFilterInitialized) return;

            const adItems = data
                .getHeaderGroups()
                .flatMap((headerGroup) => headerGroup.headers);

            setMenuItems((prevItems) => {
                if (prevItems.length > 0) {
                    return prevItems.map((item, index) => ({
                        ...item,
                        subItems:
                            index === 0
                                ? adItems.slice(1).map((adItem, adIndex) => {
                                    const filterItemIndex = activeFilters.findIndex(
                                        (filter) => filter.name === adItem.column.id,
                                    );

                                    return filterItemIndex !== -1
                                        ? {
                                            name: adItem.column.id,
                                            checked: true,
                                            condition: activeFilters[filterItemIndex].condition,
                                            value: activeFilters[filterItemIndex].value,
                                            index: adIndex,
                                        }
                                        : {
                                            name: adItem.column.id,
                                            checked: adIndex === 0,
                                            index: adIndex,
                                        };
                                })
                                : item.subItems,
                    }));
                }
                return initialMenuItems;
            });

            setSearchResults((prevItems) => {
                if (prevItems.length > 0) {
                    return prevItems.map((item, index) => ({
                        ...item,
                        subItems:
                            index === 0
                                ? adItems.slice(1).map((adItem) => ({
                                    name: adItem.column.id,
                                    checked: false,
                                }))
                                : item.subItems,
                    }));
                }
                return initialMenuItems;
            });

            setIsFilterInitialized(true);
        }, [data, activeFilters, isFilterInitialized]);


        // Effect for handling filter visibility when menu items or onClose changes
        useEffect(() => {
            if (!onClose) return;

            setIsFilterPanelVisible(false);
            onOpen?.(false);
        }, [onClose]);


        const handleSelectChange = (e, subItem, key, setState) => {
            const value = e.value;

            setMenuItems((prevItems) =>
                prevItems.map((item) => ({
                    ...item,
                    subItems: item.subItems?.map((sub) =>
                        sub.name === subItem.name
                            ? { ...sub, [key]: value }
                            : sub
                    ),
                }))
            );

            setActiveFilters((prevState) => {
                const updatedItems = prevState.map((item) =>
                    item.name === subItem.name ? { ...item, [key]: value } : item
                );
                if (!updatedItems.some((item) => item.name === subItem.name)) {
                    updatedItems.push({ name: subItem.name, [key]: value });
                }
                return updatedItems;
            });
        };


        // Handle adding new filter
        const addNewFilter = () => {
            const newSubItem = {
                name: '',
                checked: true,
                value: '',
                condition: '',
            };

            setMenuItems((prevItems) => {
                const updatedItems = prevItems.map((item) => ({
                    ...item,
                    subItems: item.subItems
                        ? [
                            ...item.subItems,
                            newSubItem,
                        ]
                        : [newSubItem],
                }));
                return updatedItems;
            });

            setFilterValues([]);
        };

        const handleInputChange = (values, subItem) => {
            if (subItem) {
                setFilterValues(values);

                setMenuItems((prevItems) =>
                    prevItems.map((item) => ({
                        ...item,
                        subItems: item.subItems?.map((sub) =>
                            sub.name === subItem.name
                                ? { ...sub, value: values }
                                : sub
                        ),
                    }))
                );
            }
            setActiveFilters((prevState) => {
                const updatedItems = prevState.map((item) =>
                    item.name === subItem.name ? { ...item, value: values } : item
                );
                if (!updatedItems.some((item) => item.name === subItem.name)) {
                    updatedItems.push({ name: subItem.name, value: values });
                }
                return updatedItems;
            });
        };


        return (
            <div className={style.ShapeTable}>
                <div className={style.filterContainer}>
                    <div
                        className={cl(style.filter, { [style.active]: isFilterPanelVisible })}
                        onClick={() => setIsFilterPanelVisible(!isFilterPanelVisible)}
                    >
                        <img src={asset('/images/filter.png')} alt="filter-logo" />
                        <p>Filter</p>
                    </div>

                    {activeFilters.length > 0 && (
                        <div className={style.filterActive}>
                            <ul>
                                {activeFilters
                                    .filter((item) => item.value)
                                    .map((item, index) => (
                                        <li key={item.name}>
                                            <p>
                                                {item.name
                                                    .replace(/([a-z])([A-Z])/g, '$1 $2')
                                                    .replace(/\b\w/g, (char) => char.toUpperCase())}
                                                :{' '}
                                            </p>
                                            <h5>{item.value}</h5>
                                            <img
                                                src={asset('/images/close.png')}
                                                alt="close-selected"
                                                onClick={() => removeSelectedFilter(item.name, index, 'list-filter')}
                                            />
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    )}
                </div>

                {isFilterPanelVisible && (
                    <div ref={ref} className={style.filterOverlay}>
                        <div className={style.filterInner}>
                            <FilterSelection
                                menuItems={menuItems}
                                availableOptions={availableOptions}
                                conditionOptions={conditionOptions}
                                onSelectChange={handleSelectChange}
                                onInputChange={handleInputChange}
                                onRemoveFilter={removeSelectedFilter}
                                activeFilters={activeFilters}
                                filterConditions={filterConditions}
                                filterValues={filterValues}
                            />
                            {menuItems.map((item) => item.subItems.find((item, index) => index === 0 && item.checked) && (
                                <div className={style.buttonContainer}>
                                    <div className={style.buttonAddFilter} onClick={addNewFilter}>
                                        <button><Icons.PlusCircled /></button>
                                    </div>
                                    <button onClick={applyFilters} disabled={activeFilters.length === 0}>Apply</button>
                                </div>
                            ))}
                            {isFilterMenuVisible && (
                                <FilterMenu
                                    menuItems={menuItems}
                                    onCheckboxChange={handleCheckboxChange}
                                    searchResults={searchResults}
                                    onSearchChange={handleSearchChange}
                                    activeFilters={activeFilters}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
);