import {ExecutableAction} from 'titan-ads/lib/shapes/ExecutableAction';
import {Literal} from 'lincd/lib/models';
import {Ad, AdSet, Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {packageName} from '../package';
import {SelectedAction} from '../components/ActionDropdown';
import {NodeShape} from 'lincd/lib/shapes/SHACL';
import {Boolean} from 'lincd-xsd/lib/shapes/Boolean';
import {xsd} from 'lincd/lib/ontologies/xsd';
import {DynamicObject} from '../utils';

interface ActionField
{
  name: string;
  label: string;
  type: string;
  placeholder?: string;
  step?: string;
  options?: {label: string;value: string}[]; // For select fields
}

export interface ActionConfig
{
  targetShape: NodeShape;
  actions: SingleActionConfig[];
}

interface SingleActionConfig
{
  value: string;
  method?: string; // Optional method field
  label: string;
  fields: ActionField[];
  onSubmit?: (formData: {[key: string]: any},selectedAction: SelectedAction,selectedItems?: DynamicObject[]) => void|{message:string}; // Accept additionalData
}

export const useBatchActions = () => {
  const adBatchActions: ActionConfig = {
    targetShape: Ad.shape,
    actions: [
      {
        value: 'turnon',
        method: 'turnOn', //preferably camelCase
        label: 'Turn On',
        fields: [notesField,usernameField],
      },
      {
        value: 'turnoff',
        method: 'turnOff',
        label: 'Turn Off',
        fields: [notesField,usernameField],
      },
      {
        value: 'change_status_guidance',
        method: 'statusGuidance',
        label: 'Change Status Guidance',
        fields: [
          {
            name: 'statusGuidance',
            label: 'Status Guidance',
            type: 'select',
            options: [
              {label: 'Keep On',value: 'keep_on'},
              {label: 'Keep Off (Not Profitable)',value: 'keep_off'},
              {label: 'Paused',value: 'paused'},
              {label: 'Untested',value: 'untested'},
            ],
          },
          notesField,
          usernameField,
        ],
        onSubmit: (formData: any,action: ExecutableAction) => {
          const {statusGuidance} = formData;
          // action.targetShape = Ad.shape;
          action.parameters.add(new Literal(statusGuidance));
        },
      },
      {
        value: 'change_bid_amount',
        method: 'setBidAmountInQuora',
        label: 'Change Adset Bid Amount',
        fields: [
          {
            name: 'adSetBid', //this name should be matched with the mapping fields/properties on utils/views/AdManagement.tsx, otherwise the value from db won't be loaded into frontend
            label: 'Adset Bid Amount',
            type: 'number',
            step: '0.01',
            placeholder: 'Enter bid amount...',
          },
          notesField,
          usernameField,
        ],
        onSubmit: (formData: any,action: ExecutableAction) => {
          action.parameters.add(new Literal(formData.adSetBid,xsd.integer));
        },
      },
    ],
  };

  const adSetBatchActions: ActionConfig = {
    targetShape: AdSet.shape,
    actions: [
      {
        value: 'turnon',
        method: 'turnOn', //preferably camelCase
        label: 'Turn On',
        fields: [notesField,usernameField],
      },
      {
        value: 'turnoff',
        method: 'turnOff',
        label: 'Turn Off',
        fields: [notesField,usernameField],
      },
      {
        value: 'change_bid_amount',
        method: 'setBidAmountInQuora',
        label: 'Change Bid Amount',
        fields: [
          {
            name: 'bidAmount',
            label: 'Bid Amount',
            type: 'number',
            placeholder: 'Enter bid amount...',
          },
          notesField,
          usernameField,
        ],
        onSubmit: (formData: any,action: ExecutableAction,selectedItems) => {
          if(selectedItems.some((item) => {
            //if the item is not created from a template, or does not have an identifier
            //then we did not create it in this dashboard, and we cannot edit it
            //so we should not allow the action to be executed
            return !item.isQuickLaunched;
          })) {
            return {message: 'Cannot edit adSets that were not created in this dashboard.\n\n' +
                'Check that column "Is Quick Launched" is "Yes"'
            };
          }
          if(selectedItems.some((item) => item.placement === 'Digest Emails')) {
            return {message: 'Cannot edit adSets with placement "Digest Emails"'};
          }
          action.parameters.add(new Literal(formData.bidAmount,xsd.integer));
        },
      },
      {
        value: 'setOptimized',
        method: 'optimized',
        label: 'Mark as optimized',
        fields: [
          {
            name: 'optimized',
            label: 'Optimized',
            type: 'boolean'
          },
          notesField,
          usernameField,
        ],
        onSubmit: (formData: any,action: ExecutableAction) => {
          action.parameters.add(Boolean.toLiteral(formData.optimized === true));
        },
      },
    ],
  };

  const campaignBatchActions: ActionConfig = {
    targetShape: Campaign.shape,
    actions: [
      {
        value: 'turnon',
        method: 'turnOn', //preferably camelCase
        label: 'Turn On',
        fields: [notesField,usernameField],
      },
      {
        value: 'turnoff',
        method: 'turnOff',
        label: 'Turn Off',
        fields: [notesField,usernameField],
      },
      {
        value: 'change_daily_maximum_budget',
        method: 'changeDailyMaximumBudget',
        label: 'Change Daily Maximum Budget',
        fields: [
          {
            name: 'dailyMaximumBudget',
            label: 'Daily Maximum Budget',
            type: 'number',
            placeholder: 'Enter budget...',
          },
          usernameField,
        ],
        onSubmit: (formData: any,action: ExecutableAction) => {
          action.parameters.add(new Literal(formData.dailyMaximumBudget));
        },
      },
    ],
  };

  const approvalBatchActions = [
    {
      value: 'approve',
      method: 'approve',
      label: 'Approve',
      fields: [usernameField],
      onSubmit: (formData: any,selectedRows) => {
        return Server.call(packageName,'approveActions',formData.userName,selectedRows);
      },
    },
    {
      value: 'decline',
      method: 'decline',
      label: 'Decline',
      fields: [usernameField],
      onSubmit: (formData: any,selectedRows) => {
        return Server.call(packageName,'declineActions',formData.userName,selectedRows);
      },
    },
  ];

  return {adBatchActions, adSetBatchActions, campaignBatchActions, approvalBatchActions};
};


const notesField: ActionField = {
  name: 'notes',
  label: 'Notes',
  type: 'textarea',
  placeholder: 'Enter notes here...',
};
const usernameField: ActionField = {
  name: 'userName',
  label: 'Name of User',
  type: 'text',
  placeholder: 'Enter your name...',
};
