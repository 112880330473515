import React from 'react';
import ActionDropdown from './ActionDropdown';
import { useBatchActions } from '../hooks/useBatchActions'; // Custom hook to handle actions

interface CampaignActionDropdownProps {
  selectedRowData: any[]; // Accept selectedRows as a prop
  onComplete?: () => void;
}
const CampaignActionDropdown: React.FC<CampaignActionDropdownProps> = ({ selectedRowData, onComplete }) => {
  const { campaignBatchActions } = useBatchActions();

  return <ActionDropdown actions={campaignBatchActions} selectedRowData={selectedRowData} onComplete={onComplete}/>;
};

export default CampaignActionDropdown;
