import { Suspense, useContext, useEffect, useState } from 'react';
import TableView from '../components/TableView';
import './DailyAdView.scss';
import styles from './DailyAdView.scss.json';
import { DayPicker } from 'react-day-picker';
import { BrandAccountSelector } from '../components/RefreshQuoraData';
import { useSuspense } from '../utils';
import { BrandAccount } from 'titan-ads/lib/shapes/TitanShapes';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../package';
import { DashboardContext } from '../contexts/Dashboard';
import { formatPercentage, formatSpend } from '../utils/data';

const DailyAdView = () => {
    const initialBrands = useSuspense(BrandAccount.loadAll());
    const [brands] = useState<{ read(): any }>(initialBrands);
    const [selected, setSelected] = useState<Date>();
    const [showDayPicker, setShowDayPicker] = useState<boolean>(false);
    const [source, setSource] = useState<any[]>([]);
    const { curAccount } = useContext(DashboardContext);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [campaignBid, setCampaignBid] = useState<any>('');

    const getDailyAdsData = async () => {
        try {
            setLoading(true);
            const adData = await Server.call(packageName, 'getDailyAdData', formatDate(selected), curAccount);
            console.log('adData', adData);
            if(adData) setSource(adData.data);
            // alert(`No Ad Matched for Date ${formatDate(selected)} for account ${curAccount.name} - ${adData}`)
            setLoading(false);
        } catch (e) {
            console.log('Error getting daily ad data for report');
            return [];
        }
    };

    function formatAdDataForTable(adDataByDay, advertiserData = []) {
        const formattedData = [];
        adDataByDay.forEach((dataEntry, index) => {
            Object.keys(dataEntry).forEach(key => {
                // const advertiserValue =
                //     advertiserData[index]?.[key] !== undefined
                //         ? advertiserData[index][key]
                //         : '';
    
                const shouldFormatAsPlainNumber = [
                    'ROAS',
                    'Traffic Source (Quora) Clicks',
                    'Tracker (TCG or Voluum?) Clicks',
                    'Advertiser Sales',
                    'Total Ads',
                    'Active Ads (Running)',
                    'Ads w/ Clicks',
                    'Ads w/ Conversions',
                    'Range # of Ads Engaged From Last Week (Min/Max)',
                ].includes(key);
    
                const shouldFormatAsPercentage = ['Gross Margin', '% of Ads w/ Clicks', '% of Ads w/ Conversions'].includes(key);
    
                formattedData.push({
                    metric: key,
                    quoraPL:
                        key === 'Campaign Bid(s)'
                            ? <input
                                type="text"
                                value={campaignBid}
                                onChange={(e) => setCampaignBid(e.target.value)}
                                className={styles.campaignBids}
                            />
                            : typeof dataEntry[key] === 'number'
                                ? shouldFormatAsPercentage
                                    ? formatPercentage(dataEntry[key])
                                    : shouldFormatAsPlainNumber
                                        ? dataEntry[key].toLocaleString()
                                        : formatSpend(dataEntry[key])
                                : dataEntry[key],
                    // advertiserPL:
                    //     typeof advertiserValue === 'number'
                    //         ? advertiserValue.toLocaleString(undefined, {
                    //             style: 'currency',
                    //             currency: 'USD',
                    //             maximumFractionDigits: 0,
                    //         })
                    //         : advertiserValue,
                    // cvr:
                    //     key === 'Advertiser Sales' && dataEntry['Clicks'] > 0
                    //         ? ((dataEntry['Advertiser Sales'] / dataEntry['Clicks']) * 100).toFixed(2) + '%'
                    //         : '',
                    // cpa:
                    //     key === 'Spend' && dataEntry['Advertiser Sales'] > 0
                    //         ? (dataEntry['Spend'] / dataEntry['Advertiser Sales']).toFixed(2)
                    //         : '',
                    // roas:
                    //     key === 'ROAS' && typeof dataEntry['ROAS'] === 'number'
                    //         ? dataEntry['ROAS'].toFixed(2)
                    //         : '',
                });
            });
        });
        return formattedData;
    }
    
      
    const formatDate = (date: Date) => {
        return date.toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' });
    }

    useEffect(() => {
        if (selected && curAccount) {
            (async () => {
                await getDailyAdsData();
            })();
        }

        if (location.pathname.includes('/view/daily-ad-report')) {
            document.querySelectorAll('.titan_DefaultLayout_main').forEach((element) => {
                (element as HTMLElement).style.maxWidth = 'none';
            });
        }
    }, [location.pathname, selected]);

    return (
        <div className={styles.dailyAdContainer}>
            <h3>Generate Report - Daily Ad View</h3>
            <Suspense fallback={<BrandAccountSelector.Loading />}>
                <BrandAccountSelector qAccounts={brands} showLabel={false} />
            </Suspense>
            <div className={styles.inputDatePicker}>
                {!showDayPicker ? (
                    <input
                        type="text"
                        readOnly
                        value={selected ? formatDate(selected) : 'Select Day'}
                        onClick={() => setShowDayPicker(!showDayPicker)}
                    />
                ) : (
                    <div className={styles.dayPicker}>
                    <DayPicker
                        mode="single"
                        required
                        captionLayout="dropdown"
                        hideNavigation
                        timeZone="America/Los_Angeles"
                        selected={selected}
                        onSelect={(date) => {
                            if(curAccount && date) {
                                setSelected(date);
                                setShowDayPicker(!showDayPicker);
                            } else {
                                alert('Please select a brand account');
                            }
                        }}
                    />
                    </div>
                )}
            </div>
            <TableView data={formatAdDataForTable(source)} isLoading={isLoading} />
        </div>
    );
};

export default DailyAdView;
