import React,{useEffect,useState} from 'react';
import {DefaultLayout} from '../layout/DefaultLayout';
import {packageName} from '../package';
import {Spinner} from '../components/Spinner';
import {Ad} from 'titan-ads/lib/shapes/TitanShapes';
import {generatePath,Link,useLocation} from 'react-router-dom';
import {ROUTES} from '../routes';
import ShapeDetail from '../components/ShapeDetail';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {AdChart} from '../components/AdChart';
import {TimeRangeProps} from '../types';
import dayjs from 'dayjs';
import {titanAds} from 'titan-ads/lib/ontologies/titan-ads';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import './AdManagementDetail.scss';
import style from './AdManagementDetail.scss.json';
import AdActionDropdown from '../components/AdActionDropdown';
import {DynamicObject} from '../utils';

function AdManagementDetail()
{
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uri = queryParams.get('uri');

  const [adData,setAdData] = useState<DynamicObject>();
  const [engagementMetric,setEngagementMetric] = useState<string>('dailyImpressions');
  const [metricData,setMetricData] = useState<DynamicObject>();

  // set default time range to last 7 days
  const [timeRange,setTimeRange] = useState<TimeRangeProps>({
    value: 'last7days',
    startDate: dayjs().subtract(7,'day').format('YYYY-MM-DD'), // default is 7 days ago,
    endDate: dayjs().format('YYYY-MM-DD'), // default is today
  });

  const fetchSingleAdData = async () => {
    if (!uri) return;
    try {
      const data = await Server.call(packageName, 'loadSingleAdData', uri);
      if (data) {
        setAdData(data);
      } else {
        setAdData(null);
      }
    } catch (error) {
      console.error('Error fetching Ad data:', error);
      setAdData(null);
    }
  };

  useEffect(() => {
    fetchSingleAdData();
  },[uri]);

  // fetch engagement metric data by uri
  useEffect(() => {
    if (!adData && !uri) return;

    Server.call(
      packageName,
      'getObservationsByShape',
      uri,
      Ad,
      [titanAds.dailyClicks,titanAds.dailyImpressions,titanAds.dailySpend],
      timeRange.startDate,
      timeRange.endDate,
    )
      .then(data => setMetricData(data))
      .catch(error => console.error('error fetching metric data:',error));
  },[timeRange,adData,uri]);

  const auth = useAuth();
  const isSemantu = auth.userAccount.email.includes('@semantu.com');

  return (
    <DefaultLayout>
      <div className={style.headerWrapper}>
        <h1 className={style.header}>
          <Link to={ROUTES.views.path}>Views</Link> &raquo;
          <Link to={generatePath(ROUTES.view_detail.path,{detail: 'ad-management'})}> Ad Management</Link> &raquo; {adData?.fullAdName}
        </h1>
        <AdActionDropdown selectedRowData={adData ? [adData] : []} onComplete={fetchSingleAdData}/>
      </div>
      {adData ? <ShapeDetail data={adData} /> : <Spinner />}
      {isSemantu && adData && (metricData ? <AdChart
        data={metricData}
        metric={engagementMetric}
        onMetricChange={setEngagementMetric}
        timeRange={timeRange}
        onTimeRangeChange={setTimeRange}
      /> : <Spinner />)}
    </DefaultLayout>

  );
}

export default AdManagementDetail;