import React from 'react';
import ActionDropdown from './ActionDropdown';
import { useBatchActions } from '../hooks/useBatchActions';
import {DynamicObject} from '../utils'; // Custom hook to handle actions

interface AdSetActionDropdownProps {
  selectedRowData: DynamicObject[]; // Accept selectedRows as a prop
  onComplete?: () => void;
}
const AdSetActionDropdown: React.FC<AdSetActionDropdownProps> = ({ selectedRowData, onComplete }) => {
  const { adSetBatchActions } = useBatchActions();

  return <ActionDropdown actions={adSetBatchActions} selectedRowData={selectedRowData} onComplete={onComplete}/>;
};

export default AdSetActionDropdown;
