import React, {useEffect, useState} from 'react'
import {DefaultLayout} from '../layout/DefaultLayout';
import {Link,useParams} from 'react-router-dom';
import AdManagement from './AdManagement';
import AdSetManagement from './AdSetManagement';
import ApprovalPage from './ApprovalPage';
import CampaignManagement from './CampaignManagement';
import MediaMixReport from './MediaMixReport';
import {ROUTES} from '../routes';
import OptimizationReport from './OptimizationReport';
import DailyAdView from './DailyAdView';

function ViewDetail() {
  const { detail } = useParams();

  const transformString = (string) => {
    return string
        .split('-') // Split the string by the hyphen
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' '); // Join the words with a space
  }
  
  return (
    <DefaultLayout>
      <h1><Link to={ROUTES.views.path}>Views</Link> &raquo; {transformString(detail)} </h1>
      {detail === 'ad-management' && <AdManagement />}
      {detail === 'adset-management' && <AdSetManagement />}
      {detail === 'campaign-management' && <CampaignManagement />}
      {detail === 'approval-page' && <ApprovalPage />}
      {detail === 'media-mix-report' && <MediaMixReport />}
      {detail === 'daily-ad-report' && <DailyAdView />}
      {detail === 'optimization-report' && <OptimizationReport />}
      
    </DefaultLayout>
  )
}

export default ViewDetail