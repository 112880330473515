import React from 'react';

export const Icons = {
  ArrowLeft: ({ color = 'currentColor', ...props }) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="m12 19-7-7 7-7" />
      <path d="M19 12H5" />
    </svg>
  ),
  ChevronDown: ({ color = 'currentColor', ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="m6 9 6 6 6-6" />
    </svg>
  ),
  ChevronUp: ({ color = 'currentColor', ...props }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="m18 15-6-6-6 6" />
    </svg>
  ),
  Cross2: ({ color = 'currentColor', ...props }) => (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  Home: ({ color = 'currentColor', width = '25', height = '25', ...props }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66667 17.2917H4.77778C2.69137 17.2917 1 15.6074 1 13.5297V7.61187C1 6.29639 1.69002 5.07648 2.81975 4.39467L7.54197 1.54474C8.74548 0.818422 10.2545 0.818422 11.458 1.54474L16.1802 4.39467C17.31 5.07648 18 6.29639 18 7.61187V13.5297C18 15.6074 16.3086 17.2917 14.2222 17.2917H12.3333M6.66667 17.2917V13.5297C6.66667 11.9715 7.93515 10.7083 9.5 10.7083C11.0648 10.7083 12.3333 11.9715 12.3333 13.5297V17.2917M6.66667 17.2917H12.3333"
        stroke="#253031"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  RightArrow: ({
    color = 'currentColor',
    width = '25',
    height = '25',
    ...props
  }) => (
    <svg
      //   width="6"
      //   height="11"
      width={width}
      height={height}
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L5 5.59615L1 10.1923"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Box: ({ color = 'currentColor', width = '25', height = '25', ...props }) => (
    <svg
      //   width="23"
      //   height="22"
      width={width}
      height={height}
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.9349 5.08433L10.6178 8.69479L10.7046 8.87758L10.7089 8.88663H10.6536H10.1536V9.15185L1.67705 5.62714L1.9349 5.08433ZM11.1536 20.5002V10.6507L11.4037 10.7547L11.5 10.5519L11.5963 10.7547L11.8464 10.6507V20.5002H11.1536ZM21.323 5.62714L12.8464 9.15185V8.88663H12.3464H12.2911L12.2954 8.87758L12.3822 8.69479L21.0651 5.08433L21.323 5.62714Z"
        fill="#D9D9D9"
        stroke="#253031"
      />
      <path
        d="M1.34375 16.3652V5.57503C1.34375 5.32469 1.50133 5.09784 1.74585 4.99617L11.225 1.0546C11.4001 0.981801 11.5999 0.981801 11.775 1.0546L21.2542 4.99617C21.4987 5.09784 21.6563 5.32469 21.6563 5.57503V16.3652C21.6563 16.6156 21.4987 16.8425 21.2542 16.9441L11.775 20.8857C11.5999 20.9584 11.4001 20.9584 11.225 20.8857L1.74585 16.9441C1.50133 16.8425 1.34375 16.6156 1.34375 16.3652Z"
        stroke="#253031"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.42188 3.05225L16.1761 7.10816C16.4206 7.20983 16.5781 7.43668 16.5781 7.68702V11.4985"
        stroke="#253031"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  Report: ({
    color = 'currentColor',
    width = '25',
    height = '25',
    ...props
  }) => (
    <svg
      //   width="19"
      //   height="19"
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66667 18H12.3333M6.66667 18V13.2778M6.66667 18H1.56667C1.25371 18 1 17.7463 1 17.4333V13.8444C1 13.5315 1.25371 13.2778 1.56667 13.2778H6.66667M12.3333 18V6.66667M12.3333 18H17.4333C17.7463 18 18 17.7463 18 17.4333V1.56667C18 1.25371 17.7463 1 17.4333 1H12.9C12.587 1 12.3333 1.25371 12.3333 1.56667V6.66667M6.66667 13.2778V7.23333C6.66667 6.92037 6.92037 6.66667 7.23333 6.66667H12.3333"
        stroke="#253031"
      />
    </svg>
  ),
  Mynd: ({ color = 'currentColor', width = '25', height = '25', ...props }) => (
    <svg
      width="60"
      height="16"
      viewBox="0 0 67 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7224 0.0536003H14.5C14.7811 0.0536003 14.9216 0.194133 14.9216 0.475199V15.5784C14.9216 15.8595 14.7811 16 14.5 16H12.9624C12.6813 16 12.5408 15.8595 12.5408 15.5784V3.0544H12.4416L9.2176 12.1312C9.1184 12.3957 8.93653 12.528 8.672 12.528H7.0848C6.8368 12.528 6.6632 12.3957 6.564 12.1312L3.2656 3.0296H3.1664V15.5784C3.1664 15.7437 3.13333 15.8595 3.0672 15.9256C3.0176 15.9752 2.91013 16 2.7448 16H1.232C0.950933 16 0.8104 15.8595 0.8104 15.5784V0.475199C0.8104 0.194133 0.950933 0.0536003 1.232 0.0536003H4.0096C4.19147 0.0536003 4.32373 0.144533 4.4064 0.3264L7.804 9.8992H7.928L11.3504 0.3264C11.3835 0.144533 11.5075 0.0536003 11.7224 0.0536003ZM27.2929 16H25.7057C25.4247 16 25.2841 15.8595 25.2841 15.5784V10.0976L20.6465 0.5C20.5804 0.367734 20.5804 0.260267 20.6465 0.1776C20.7127 0.0949336 20.8036 0.0536003 20.9193 0.0536003H22.9033C23.1183 0.0536003 23.2836 0.194133 23.3993 0.475199L26.4497 7.2952H26.5985L29.5993 0.475199C29.6985 0.194133 29.8639 0.0536003 30.0953 0.0536003H32.0793C32.2116 0.0536003 32.3025 0.0949336 32.3521 0.1776C32.4183 0.260267 32.4183 0.367734 32.3521 0.5L27.7145 10.0976V15.5784C27.7145 15.8595 27.574 16 27.2929 16ZM40.0355 16H38.4979C38.2168 16 38.0763 15.8595 38.0763 15.5784V0.475199C38.0763 0.194133 38.2168 0.0536003 38.4979 0.0536003H39.8867C40.1512 0.0536003 40.3331 0.144533 40.4323 0.3264L46.4339 11.1144H46.5331V0.475199C46.5331 0.194133 46.6736 0.0536003 46.9547 0.0536003H48.4923C48.7733 0.0536003 48.9139 0.194133 48.9139 0.475199V15.5784C48.9139 15.8595 48.7733 16 48.4923 16H47.1531C46.8885 16 46.6736 15.8677 46.5083 15.6032L40.5563 4.9392H40.4571V15.5784C40.4571 15.8595 40.3165 16 40.0355 16Z"
        fill="#5E5E5E"
      />
      <path
        d="M58.6853 13.892H62.1821C63.3725 13.892 63.9677 13.2803 63.9677 12.0568V3.9968C63.9677 2.77333 63.3725 2.1616 62.1821 2.1616H58.6853C58.5034 2.1616 58.4125 2.24427 58.4125 2.4096V13.6192C58.4125 13.8011 58.5034 13.892 58.6853 13.892ZM56.0069 15.5784V0.475199C56.0069 0.194133 56.1474 0.0536003 56.4285 0.0536003H62.5293C63.7362 0.0536003 64.6786 0.392533 65.3565 1.0704C66.0509 1.73173 66.3981 2.67413 66.3981 3.8976V12.156C66.3981 13.3629 66.0509 14.3053 65.3565 14.9832C64.6786 15.6611 63.7362 16 62.5293 16H56.4285C56.1474 16 56.0069 15.8595 56.0069 15.5784Z"
        fill="#47C0AE"
      />
    </svg>
  ),
  MyndLogo: ({
    color = 'currentColor',
    width = '25',
    height = '25',
    ...props
  }) => (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="7.12939" width="6.5625" height="6.29059" rx="1" fill="#5E5E5E" />
      <rect
        x="7.4375"
        y="7.12939"
        width="6.5625"
        height="6.29059"
        rx="1"
        fill="#5E5E5E"
      />
      <rect x="7.4375" width="6.5625" height="6.29059" rx="1" fill="#47C0AE" />
    </svg>
  ),
  Network: ({
    color = 'currentColor',
    width = '25',
    height = '25',
    ...props
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      // class="lucide lucide-globe"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M12 2a14.5 14.5 0 0 0 0 20 14.5 14.5 0 0 0 0-20" />
      <path d="M2 12h20" />
    </svg>
  ),
  Burger: ({
    color = 'currentColor',
    width = '25px',
    height = '25px',
    ...props
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width={width}
      height={height}
    >
      <path d="M 3 9 A 1.0001 1.0001 0 1 0 3 11 L 47 11 A 1.0001 1.0001 0 1 0 47 9 L 3 9 z M 3 24 A 1.0001 1.0001 0 1 0 3 26 L 47 26 A 1.0001 1.0001 0 1 0 47 24 L 3 24 z M 3 39 A 1.0001 1.0001 0 1 0 3 41 L 47 41 A 1.0001 1.0001 0 1 0 47 39 L 3 39 z" />
    </svg>
  ),
  Italic: ({ color = 'currentColor', ...props }) => (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.67494 3.50017C5.67494 3.25164 5.87641 3.05017 6.12494 3.05017H10.6249C10.8735 3.05017 11.0749 3.25164 11.0749 3.50017C11.0749 3.7487 10.8735 3.95017 10.6249 3.95017H9.00587L7.2309 11.05H8.87493C9.12345 11.05 9.32493 11.2515 9.32493 11.5C9.32493 11.7486 9.12345 11.95 8.87493 11.95H4.37493C4.1264 11.95 3.92493 11.7486 3.92493 11.5C3.92493 11.2515 4.1264 11.05 4.37493 11.05H5.99397L7.76894 3.95017H6.12494C5.87641 3.95017 5.67494 3.7487 5.67494 3.50017Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  AlignLeft: ({ color = 'currentColor', ...props }) => (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 4.5C2 4.22386 2.22386 4 2.5 4H12.5C12.7761 4 13 4.22386 13 4.5C13 4.77614 12.7761 5 12.5 5H2.5C2.22386 5 2 4.77614 2 4.5ZM2 7.5C2 7.22386 2.22386 7 2.5 7H7.5C7.77614 7 8 7.22386 8 7.5C8 7.77614 7.77614 8 7.5 8H2.5C2.22386 8 2 7.77614 2 7.5ZM2 10.5C2 10.2239 2.22386 10 2.5 10H10.5C10.7761 10 11 10.2239 11 10.5C11 10.7761 10.7761 11 10.5 11H2.5C2.22386 11 2 10.7761 2 10.5Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  AlignCenter: ({ color = 'currentColor', ...props }) => (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 4.5C2 4.22386 2.22386 4 2.5 4H12.5C12.7761 4 13 4.22386 13 4.5C13 4.77614 12.7761 5 12.5 5H2.5C2.22386 5 2 4.77614 2 4.5ZM4 7.5C4 7.22386 4.22386 7 4.5 7H10.5C10.7761 7 11 7.22386 11 7.5C11 7.77614 10.7761 8 10.5 8H4.5C4.22386 8 4 7.77614 4 7.5ZM3 10.5C3 10.2239 3.22386 10 3.5 10H11.5C11.7761 10 12 10.2239 12 10.5C12 10.7761 11.7761 11 11.5 11H3.5C3.22386 11 3 10.7761 3 10.5Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  AlignRight: ({ color = 'currentColor', ...props }) => (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 4.5C2 4.22386 2.22386 4 2.5 4H12.5C12.7761 4 13 4.22386 13 4.5C13 4.77614 12.7761 5 12.5 5H2.5C2.22386 5 2 4.77614 2 4.5ZM7 7.5C7 7.22386 7.22386 7 7.5 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H7.5C7.22386 8 7 7.77614 7 7.5ZM4 10.5C4 10.2239 4.22386 10 4.5 10H12.5C12.7761 10 13 10.2239 13 10.5C13 10.7761 12.7761 11 12.5 11H4.5C4.22386 11 4 10.7761 4 10.5Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  CaretSort: ({ color = 'currentColor', ...props }) => (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.93179 5.43179C4.75605 5.60753 4.75605 5.89245 4.93179 6.06819C5.10753 6.24392 5.39245 6.24392 5.56819 6.06819L7.49999 4.13638L9.43179 6.06819C9.60753 6.24392 9.89245 6.24392 10.0682 6.06819C10.2439 5.89245 10.2439 5.60753 10.0682 5.43179L7.81819 3.18179C7.73379 3.0974 7.61933 3.04999 7.49999 3.04999C7.38064 3.04999 7.26618 3.0974 7.18179 3.18179L4.93179 5.43179ZM10.0682 9.56819C10.2439 9.39245 10.2439 9.10753 10.0682 8.93179C9.89245 8.75606 9.60753 8.75606 9.43179 8.93179L7.49999 10.8636L5.56819 8.93179C5.39245 8.75606 5.10753 8.75606 4.93179 8.93179C4.75605 9.10753 4.75605 9.39245 4.93179 9.56819L7.18179 11.8182C7.35753 11.9939 7.64245 11.9939 7.81819 11.8182L10.0682 9.56819Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  MagnifyingGlass: ({ color = 'currentColor', ...props }) => (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  Pencil: ({
    color = 'currentColor',
    width = '15',
    height = '15',
    ...props
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      x="0"
      y="0"
      enableBackground="new 0 0 528.899 528.899"
      version="1.1"
      viewBox="0 0 528.899 528.899"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M328.883 89.125l107.59 107.589-272.34 272.34L56.604 361.465l272.279-272.34zm189.23-25.948l-47.981-47.981c-18.543-18.543-48.653-18.543-67.259 0l-45.961 45.961 107.59 107.59 53.611-53.611c14.382-14.383 14.382-37.577 0-51.959zM.3 512.69c-1.958 8.812 5.998 16.708 14.811 14.565l119.891-29.069L27.473 390.597.3 512.69z"></path>
    </svg>
  ),
  RubbishBin: ({
    color = 'currentColor',
    width = '17',
    height = '17',
    ...props
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      version="1.1"
      viewBox="0 0 408.483 408.483"
      xmlSpace="preserve"
    >
      <path d="M87.748 388.784c.461 11.01 9.521 19.699 20.539 19.699h191.911c11.018 0 20.078-8.689 20.539-19.699l13.705-289.316H74.043l13.705 289.316zm159.907-217.455a8.35 8.35 0 018.35-8.349h13.355a8.351 8.351 0 018.35 8.349v165.293a8.35 8.35 0 01-8.35 8.349h-13.355a8.35 8.35 0 01-8.35-8.349V171.329zm-58.439 0a8.35 8.35 0 018.349-8.349h13.355a8.35 8.35 0 018.349 8.349v165.293a8.348 8.348 0 01-8.349 8.349h-13.355a8.348 8.348 0 01-8.349-8.349V171.329zm-58.441 0a8.35 8.35 0 018.349-8.349h13.356a8.35 8.35 0 018.349 8.349v165.293a8.349 8.349 0 01-8.349 8.349h-13.356a8.348 8.348 0 01-8.349-8.349V171.329zM343.567 21.043h-88.535V4.305A4.305 4.305 0 00250.727 0h-92.971a4.305 4.305 0 00-4.304 4.305v16.737H64.916c-7.125 0-12.9 5.776-12.9 12.901V74.47h304.451V33.944c0-7.125-5.775-12.901-12.9-12.901z"></path>
    </svg>
  ),
  UpArrow: ({
    color = 'currentColor',
    width = '15',
    height = '15',
    ...props
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      enableBackground="new 0 0 240.835 240.835"
      version="1.1"
      viewBox="0 0 240.835 240.835"
      xmlSpace="preserve"
    >
      <path d="M129.007 57.819c-4.68-4.68-12.499-4.68-17.191 0L3.555 165.803c-4.74 4.74-4.74 12.427 0 17.155 4.74 4.74 12.439 4.74 17.179 0l99.683-99.406 99.671 99.418c4.752 4.74 12.439 4.74 17.191 0 4.74-4.74 4.74-12.427 0-17.155L129.007 57.819z"></path>
    </svg>
  ),
  DownArrow: ({
    color = 'currentColor',
    width = '15',
    height = '15',
    ...props
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      enableBackground="new 0 0 512 512"
      viewBox="0 0 240.835 240.835"
    >
      <path
        d="M129.007 57.819c-4.68-4.68-12.499-4.68-17.191 0L3.555 165.803c-4.74 4.74-4.74 12.427 0 17.155 4.74 4.74 12.439 4.74 17.179 0l99.683-99.406 99.671 99.418c4.752 4.74 12.439 4.74 17.191 0 4.74-4.74 4.74-12.427 0-17.155L129.007 57.819z"
        data-original="#000000"
        transform="rotate(-180 120.417 120.417)"
      ></path>
    </svg>
  ),
  LeftRightArrow: ({
    color = 'currentColor',
    width = '15',
    height = '15',
    ...props
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512 512"
    >
      <path d="M192 424a23.928 23.928 0 01-16.971-7.029l-144-144a24 24 0 010-33.942l144-144a24 24 0 0133.942 33.942L81.941 256l127.03 127.029A24 24 0 01192 424zm144.971-7.029l144-144a24 24 0 000-33.942l-144-144a24 24 0 00-33.942 33.942L430.059 256l-127.03 127.029a24 24 0 0033.942 33.942z"></path>
    </svg>
  ),
  DoubleLeftArrow: ({
    color = 'currentColor',
    width = '15',
    height = '15',
    ...props
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512 512"
      fill={color}
    >
      <path
        fillRule="evenodd"
        d="M52.246 239.578c-9.043 9.043-9.043 23.803 0 32.846L229.378 449.55c9.057 9.057 23.79 9.057 32.846 0l9.908-9.908c9.065-9.065 9.067-23.79.001-32.857l-134.37-134.357c-9.067-9.065-9.067-23.793 0-32.858l134.369-134.358c9.067-9.066 9.066-23.79-.001-32.856l-9.908-9.908c-9.043-9.043-23.803-9.043-32.846 0zM416.988 62.446c9.052-9.053 23.799-9.052 32.851 0l9.908 9.908c9.048 9.048 9.049 23.808 0 32.857L325.384 239.57c-9.066 9.066-9.066 23.792 0 32.858l134.364 134.358c9.052 9.051 9.051 23.806 0 32.857l-9.908 9.908c-9.056 9.056-23.795 9.057-32.851 0L255.591 288.148c-45.274 45.229-33.822 33.759-.033-.033l-15.69-15.691c-9.049-9.049-9.048-23.798 0-32.847z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  DoubleRightArrow: ({
    color = 'currentColor',
    width = '15',
    height = '15',
    ...props
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      fill={color}
    >
      <path
        fillRule="evenodd"
        d="M52.246 239.578c-9.043 9.043-9.043 23.803 0 32.846L229.378 449.55c9.057 9.057 23.79 9.057 32.846 0l9.908-9.908c9.065-9.065 9.067-23.79.001-32.857l-134.37-134.357c-9.067-9.065-9.067-23.793 0-32.858l134.369-134.358c9.067-9.066 9.066-23.79-.001-32.856l-9.908-9.908c-9.043-9.043-23.803-9.043-32.846 0zM416.988 62.446c9.052-9.053 23.799-9.052 32.851 0l9.908 9.908c9.048 9.048 9.049 23.808 0 32.857L325.384 239.57c-9.066 9.066-9.066 23.792 0 32.858l134.364 134.358c9.052 9.051 9.051 23.806 0 32.857l-9.908 9.908c-9.056 9.056-23.795 9.057-32.851 0L255.591 288.148c-45.274 45.229-33.822 33.759-.033-.033l-15.69-15.691c-9.049-9.049-9.048-23.798 0-32.847z"
        clipRule="evenodd"
        data-original="#000000"
        transform="rotate(-180 256 256)"
      ></path>
    </svg>
  ),
  SingleRightArrow: ({
    color = 'currentColor',
    width = '15',
    height = '15',
    ...props
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      enableBackground="new 0 0 492.004 492.004"
      version="1.1"
      viewBox="0 0 492.004 492.004"
      xmlSpace="preserve"
    >
      <path d="M382.678 226.804L163.73 7.86C158.666 2.792 151.906 0 144.698 0s-13.968 2.792-19.032 7.86l-16.124 16.12c-10.492 10.504-10.492 27.576 0 38.064L293.398 245.9l-184.06 184.06c-5.064 5.068-7.86 11.824-7.86 19.028 0 7.212 2.796 13.968 7.86 19.04l16.124 16.116c5.068 5.068 11.824 7.86 19.032 7.86s13.968-2.792 19.032-7.86L382.678 265c5.076-5.084 7.864-11.872 7.848-19.088.016-7.244-2.772-14.028-7.848-19.108z"></path>
    </svg>
  ),
  SingleLeftArrow: ({
    color = 'currentColor',
    width = '15',
    height = '15',
    ...props
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={color}
      enableBackground="new 0 0 512 512"
      viewBox="0 0 492.004 492.004"
    >
      <path
        d="M382.678 226.804L163.73 7.86C158.666 2.792 151.906 0 144.698 0s-13.968 2.792-19.032 7.86l-16.124 16.12c-10.492 10.504-10.492 27.576 0 38.064L293.398 245.9l-184.06 184.06c-5.064 5.068-7.86 11.824-7.86 19.028 0 7.212 2.796 13.968 7.86 19.04l16.124 16.116c5.068 5.068 11.824 7.86 19.032 7.86s13.968-2.792 19.032-7.86L382.678 265c5.076-5.084 7.864-11.872 7.848-19.088.016-7.244-2.772-14.028-7.848-19.108z"
        data-original="#000000"
        transform="rotate(180 246.002 246.002)"
      ></path>
    </svg>
  ),
  Close: ({
    color = 'currentColor',
    width = '15',
    height = '15',
    ...props
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 512 512"
      fill={color}
    >
      <path d="M292.2 256l109.9-109.9c10-10 10-26.2 0-36.2s-26.2-10-36.2 0L256 219.8 146.1 109.9c-10-10-26.2-10-36.2 0s-10 26.2 0 36.2L219.8 256 109.9 365.9c-10 10-10 26.2 0 36.2 5 5 11.55 7.5 18.1 7.5s13.1-2.5 18.1-7.5L256 292.2l109.9 109.9c5 5 11.55 7.5 18.1 7.5s13.1-2.5 18.1-7.5c10-10 10-26.2 0-36.2z"></path>
    </svg>
  ),

  Plus: ({ color = 'currentColor', width = '15', height = '15', ...props }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  PlusCircled: ({
    color = 'currentColor',
    width = '80',
    height = '80',
    ...props
  }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49991 0.876892C3.84222 0.876892 0.877075 3.84204 0.877075 7.49972C0.877075 11.1574 3.84222 14.1226 7.49991 14.1226C11.1576 14.1226 14.1227 11.1574 14.1227 7.49972C14.1227 3.84204 11.1576 0.876892 7.49991 0.876892ZM1.82707 7.49972C1.82707 4.36671 4.36689 1.82689 7.49991 1.82689C10.6329 1.82689 13.1727 4.36671 13.1727 7.49972C13.1727 10.6327 10.6329 13.1726 7.49991 13.1726C4.36689 13.1726 1.82707 10.6327 1.82707 7.49972ZM7.50003 4C7.77617 4 8.00003 4.22386 8.00003 4.5V7H10.5C10.7762 7 11 7.22386 11 7.5C11 7.77614 10.7762 8 10.5 8H8.00003V10.5C8.00003 10.7761 7.77617 11 7.50003 11C7.22389 11 7.00003 10.7761 7.00003 10.5V8H4.50003C4.22389 8 4.00003 7.77614 4.00003 7.5C4.00003 7.22386 4.22389 7 4.50003 7H7.00003V4.5C7.00003 4.22386 7.22389 4 7.50003 4Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  Table: ({
    color = 'currentColor',
    width = '15',
    height = '15',
    ...props
  }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2H12.5C12.7761 2 13 2.22386 13 2.5V5H8V2ZM7 5V2H2.5C2.22386 2 2 2.22386 2 2.5V5H7ZM2 6V9H7V6H2ZM8 6H13V9H8V6ZM8 10H13V12.5C13 12.7761 12.7761 13 12.5 13H8V10ZM2 12.5V10H7V13H2.5C2.22386 13 2 12.7761 2 12.5ZM1 2.5C1 1.67157 1.67157 1 2.5 1H12.5C13.3284 1 14 1.67157 14 2.5V12.5C14 13.3284 13.3284 14 12.5 14H2.5C1.67157 14 1 13.3284 1 12.5V2.5Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  Check: ({
    color = 'currentColor',
    width = '15',
    height = '15',
    ...props
  }) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  Calendar: ({
    color = 'currentColor',
    width = '15',
    height = '15',
    ...props
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      className="w-6 h-6 text-gray-800 dark:text-white"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M5 5a1 1 0 001-1 1 1 0 112 0 1 1 0 001 1h1a1 1 0 001-1 1 1 0 112 0 1 1 0 001 1h1a1 1 0 001-1 1 1 0 112 0 1 1 0 001 1 2 2 0 012 2v1a1 1 0 01-1 1H4a1 1 0 01-1-1V7a2 2 0 012-2zM3 19v-7a1 1 0 011-1h16a1 1 0 011 1v7a2 2 0 01-2 2H5a2 2 0 01-2-2zm6.01-6a1 1 0 10-2 0 1 1 0 002 0zm2 0a1 1 0 112 0 1 1 0 01-2 0zm6 0a1 1 0 10-2 0 1 1 0 002 0zm-10 4a1 1 0 112 0 1 1 0 01-2 0zm6 0a1 1 0 10-2 0 1 1 0 002 0zm2 0a1 1 0 112 0 1 1 0 01-2 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  Eye: ({
    color = 'currentColor',
    width = '15',
    height = '15',
    ...props
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="#000000"
      viewBox="0 0 24 24"
    >
      <path
        fill="#000000"
        d="M9.75 12a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0z"
      ></path>
      <path
        fill="#000000"
        fillRule="evenodd"
        d="M2 12c0 1.64.425 2.191 1.275 3.296C4.972 17.5 7.818 20 12 20c4.182 0 7.028-2.5 8.725-4.704C21.575 14.192 22 13.639 22 12c0-1.64-.425-2.191-1.275-3.296C19.028 6.5 16.182 4 12 4 7.818 4 4.972 6.5 3.275 8.704 2.425 9.81 2 10.361 2 12zm10-3.75a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  Filter: ({color = 'currentColor', width = 15, height = 15, ...props}) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M22 3H2l8 9.46V19l4 2v-8.54z"></path>
    </svg>
  ),
  SliderHorizontal: ({color = 'currentColor', width = 15, height = 15, ...props}) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M21 4h-7M10 4H3M21 12h-9M8 12H3M21 20h-5M12 20H3M14 2v4M8 10v4M16 18v4"></path>
    </svg>
  ),
  Search: ({color = 'currentColor', width = 15, height = 15, ...props}) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
    >
      <circle cx="11" cy="11" r="8"></circle>
      <path d="m21 21-4.3-4.3"></path>
    </svg>
  )
};
