import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {Suspense, useContext, useEffect, useState} from 'react';
import {AccountCard} from 'titan-ads/lib/components/AccountCard';
import {List} from 'titan-ads/lib/components/List';
import {Ad,AdSet,BrandAccount,Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {QuoraAccount} from 'titan-ads/lib/shapes/QuoraAccount';
import {CreateNewAccount} from '../components/CreateNewAccount';
import {Spinner} from '../components/Spinner';
import {ThemeContext} from '../contexts/Theme';
import {DefaultLayout} from '../layout/DefaultLayout';
import {useSuspense} from '../utils';
import './Accounts.scss';
import * as styles from './Accounts.scss.json';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';

export default function Sheets() {

  let account = useAuth().userAccount;
  if(!account.email.includes('@semantu.com')) {
    return null;
  }
  return (
    <DefaultLayout>
      <h1>Sheet data recovery</h1>
      {/*<input t*/}
    </DefaultLayout>
  );
}
