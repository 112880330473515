import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import React,{Suspense, useContext, useEffect, useState} from 'react';
import {AccountCard} from 'titan-ads/lib/components/AccountCard';
import {List} from 'titan-ads/lib/components/List';
import {Ad,AdSet,BrandAccount,Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {QuoraAccount} from 'titan-ads/lib/shapes/QuoraAccount';
import {CreateNewAccount} from '../components/CreateNewAccount';
import {Spinner} from '../components/Spinner';
import {ThemeContext} from '../contexts/Theme';
import {DefaultLayout} from '../layout/DefaultLayout';
import {useSuspense} from '../utils';
import './Accounts.scss';
import * as styles from './Accounts.scss.json';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import { packageName } from '../package';
import {Server} from 'lincd-server-utils/lib/utils/Server';

export default function RecoverData() {

  const startDate = React.createRef<HTMLInputElement>();
  const endDate = React.createRef<HTMLInputElement>();
  const email = React.createRef<HTMLInputElement>();

  let auth = useAuth();
  if(!auth.userAccount.email.includes('@semantu.com')) {
    return <DefaultLayout><h1>Unauthorized</h1></DefaultLayout>;
  }
  const submit = (e: React.MouseEvent) => {
    e.preventDefault();
    console.log("Recovering data");
    Server.call(packageName, "recoverData", {
      startDate: startDate.current?.value,
      endDate: endDate.current?.value,
      email: email.current?.value
    });
    alert('Data recovery started, you will receive progress emails');

  }

  let defaultStart = new Date().toDateString().split('T')[0].split('-').reverse().join("-");
  let defaultEndDate = new Date();
  defaultEndDate.setDate(0);
  let defaultEnd = defaultEndDate.toDateString().split('T')[0].split('-').reverse().join("-");

  return (
    <DefaultLayout>
      <h1>Recover Data</h1>
      <p>
        Re-run the cronjob that retrieves data from the Quora API for certain dates.
        Due to API limits, one day will be recovered every 30 minutes, until the process is complete.
      </p>
      <form>
        <p>From <input ref={startDate} defaultValue={defaultStart} type={"date"} name={"startDate"} />&nbsp;
        until <input ref={endDate} type={"date"} name={"endDate"}  defaultValue={defaultEnd} /></p>
        <p>Email (for progress results): <input ref={email} type={"email"} name={"email"} placeholder={'email'} /></p>
        <button type="submit" onClick={submit}>Recover Data</button>
      </form>
    </DefaultLayout>
  );
}
