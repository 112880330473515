import React, { useEffect, useRef, useState } from 'react';
import { TimeRangeProps } from '../types';
import { DayPicker } from "react-day-picker";
import style from './FilterTimeRange.scss.json';
import './FilterTimeRange.scss';
import dayjs from 'dayjs';
import { cl } from 'lincd/lib/utils/ClassNames';
import { Select } from './Select';

interface FilterTimeRangeProps {
    name: string;
    label?: string;
    timeRange: TimeRangeProps;
    onHandleChange?: (timeRange: TimeRangeProps) => void;
    variant?: 'default' | 'outline';
}

export function FilterTimeRange({name, label, timeRange, onHandleChange, variant = 'default'}: FilterTimeRangeProps) {
    // custom time range state
    // we need this to handle custom time range
    const [customTimeRange, setCustomTimeRange] = useState<TimeRangeProps>({
        value: timeRange?.value || 'last30days',
        startDate: timeRange?.startDate || dayjs().subtract(30, 'day').format('YYYY-MM-DD'), // default is 7 days ago
        endDate: timeRange?.endDate || dayjs().format('YYYY-MM-DD') // default is today, 
    });

    // state to control calendar visibility
    const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(customTimeRange.value === 'custom');
    const calendarRef = useRef(null);

    // handle click outside to close the calendar
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setIsCalendarVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [calendarRef]);

    // handle time range change 
    const handleTimeRangeChange = (e) => {
        const value = e.target.value;

        // set start and end date based on the selected value
        let start: string, end: string;
        switch (value) {
            case 'today':
                start = dayjs().format('YYYY-MM-DD');
                end = dayjs().format('YYYY-MM-DD');
                break;
            case 'last7days':
                start = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
                end = dayjs().format('YYYY-MM-DD');
                break;
            case 'last14days':
                start = dayjs().subtract(14, 'day').format('YYYY-MM-DD');
                end = dayjs().format('YYYY-MM-DD');
                break;
            case 'currentMonth':
                start = dayjs().startOf('month').format('YYYY-MM-DD');
                end = dayjs().format('YYYY-MM-DD');
                break;
            case 'last30days':
                start = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
                end = dayjs().format('YYYY-MM-DD');
                break;
            case 'last60days':
                start = dayjs().subtract(60, 'day').format('YYYY-MM-DD');
                end = dayjs().format('YYYY-MM-DD');
                break;
            case 'last90days':
                start = dayjs().subtract(90, 'day').format('YYYY-MM-DD');
                end = dayjs().format('YYYY-MM-DD');
                break;
            default:
                start = '';
                end = '';
        }

        setCustomTimeRange((prev) => {
            const updatedRange = { ...prev, value, startDate: start, endDate: end };

            // if not custom, store to the parent component
            if (value !== 'custom' && onHandleChange) {
                onHandleChange(updatedRange);
            }

            // update calendar visibility
            setIsCalendarVisible(value === 'custom');

            return updatedRange;
        });
    };

    // handle custom time range date change
    const handleDateRangeChange = (selected) => {
        const { from, to } = selected;
        
        // get the start and end date
        // e.g. 2024-10-27
        const startDate = from ? dayjs(from).format('YYYY-MM-DD') : '';
        const endDate = to ? dayjs(to).format('YYYY-MM-DD'): '';

        setCustomTimeRange((prev) => {
            const updatedRange = { ...prev, startDate, endDate };

            // if startDate and endDate are valid, store to the parent component
            if (startDate && endDate && onHandleChange) {
                onHandleChange(updatedRange);
            }

            return updatedRange;
        });
    }

    return (
        <>
            <div className={style.timeRange}>
                {label && <label htmlFor={name}>{label}</label>}
                <Select 
                    name={name}
                    className={cl(style.timeRangeSelect, style[variant])} 
                    value={customTimeRange.value} 
                    onChange={handleTimeRangeChange} 
                    onClick={() => {
                        // open the calendar when custom is selected
                        if (customTimeRange.value === 'custom') {
                            setIsCalendarVisible(true);
                        }
                    }}
                >
                    <option value="today">Today</option>
                    <option value="last7days">Last 7 days</option>
                    <option value="last14days">Last 14 days</option>
                    <option value="currentMonth">Current month</option>
                    <option value="last30days">Last 30 days</option>
                    {/*<option value="last60days">Last 60 days</option>*/}
                    {/*<option value="last90days">Last 90 days</option>*/}
                    {/*<option value="custom">Custom</option>*/}
                </Select>
                {customTimeRange.value === 'custom' && isCalendarVisible && (
                  <div className={style.timeRangeCalendar} ref={calendarRef}>
                    <DayPicker
                      mode="range"
                      disabled={{ after: new Date() }}
                      selected={
                        customTimeRange.startDate && customTimeRange.endDate ? 
                            { 
                                from: new Date(customTimeRange.startDate), 
                                to: new Date(customTimeRange.endDate) 
                            } : 
                            {
                                // set default date range to last 3 days
                                from: new Date(new Date().setDate(new Date().getDate() - 3)), 
                                to: new Date()
                            } 
                        }
                      onSelect={handleDateRangeChange}
                    />
                  </div>
                )}
            </div>
        </>
    )
}