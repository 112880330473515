import React from 'react';
import './ShapeDetail.scss';
import style from './ShapeDetail.scss.json';
import {formatValue} from '../utils/data';

function ShapeDetail({data,skip}: {data: any,skip?: string[]})
{
  return (
    <div className={style.shapeDetailContainer}>
      {Object.entries(data).filter(([key]) => key !== 'id').map(([key,value]) => {
          // if(!value || (value instanceof ShapeSet && value.size === 0)) {
          //   return;
          // }
          if (skip && skip.includes(key))
          {
            return;
          }
          if (value !== 'undefined' && value !== null && value !== '')
          {
            return <div className={style.detailItem} key={key}>
          <span className={style.label}>{key.replace(/([a-z])([A-Z])/g,'$1 $2')
            .replace(/^./,(firstChar) => firstChar.toUpperCase())}:</span>
              <span className={style.value}>{formatValue(value) || '-'}</span>
            </div>;
          }
        },
      )}
    </div>
  );
}

export default ShapeDetail;