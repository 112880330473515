import React,{useEffect,useState} from 'react';
import {Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {DefaultLayout} from '../layout/DefaultLayout';
import {ROUTES} from '../routes';
import ShapeDetail from '../components/ShapeDetail';
import {DynamicObject} from '../utils';
import {generatePath,Link,useLocation} from 'react-router-dom';
import {packageName} from '../package';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {AdChart} from '../components/AdChart';
import {TimeRangeProps} from '../types';
import dayjs from 'dayjs';
import {titanAds} from 'titan-ads/lib/ontologies/titan-ads';
import {AdChildren} from '../components/AdChildren';
import { Spinner } from '../components/Spinner';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import './CampaignManagementDetail.scss';
import style from './CampaignManagementDetail.scss.json';
import CampaignActionDropdown from '../components/CampaignActionDropdown';

function CampaignManagementDetail()
{
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uri = queryParams.get('uri');
  const id = uri.split('/').pop();

  const [campaignData,setCampaignData] = useState<DynamicObject>();

  const fetchSingleCampaignData = async () => {
    if (!uri) return;
    try {
      const data = await Server.call(packageName, 'loadSingleCampaignData', uri);
      if (data) {
        setCampaignData(data);
      } else {
        setCampaignData(null);
      }
    } catch (error) {
      console.error('Error fetching Campaign data:', error);
      setCampaignData(null);
    }
  };

  useEffect(() => {
    fetchSingleCampaignData();
  }, [uri]);

  return (
    <DefaultLayout>
      <div className={style.headerWrapper}>
        <h1 className={style.header}>
          <Link to={ROUTES.views.path}>Views</Link> &raquo;{' '}
          <Link to={generatePath(ROUTES.view_detail.path,{detail: 'campaign-management'})}>Campaign
            Management</Link> &raquo; {id} {/* {campaignData?.name || campaignData?.quoraName || campaignData?.identifier} */}
        </h1>
        <CampaignActionDropdown selectedRowData={campaignData ? [campaignData] : []} onComplete={fetchSingleCampaignData}/>
      </div>
      <CampaignDetail id={uri} data={campaignData}/>
    </DefaultLayout>
  );
}

function CampaignDetail({id,data}: {id: string,data?: DynamicObject})
{
  const [engagementMetric,setEngagementMetric] = useState<string>('dailyImpressions');
  const [metricData,setMetricData] = useState<DynamicObject>();

  // set default time range to last 7 days
  const [timeRange,setTimeRange] = useState<TimeRangeProps>({
    value: 'last7days',
    startDate: dayjs().subtract(7,'day').format('YYYY-MM-DD'), // default is 7 days ago,
    endDate: dayjs().format('YYYY-MM-DD'), // default is today
  });

  // fetch engagement metric data by uri
  useEffect(() => {
    if (!data && !id) return;

    Server.call(
      packageName,
      'getObservationsByShape',
      id,
      Campaign,
      [titanAds.dailyClicks,titanAds.dailyImpressions,titanAds.dailySpend],
      timeRange.startDate,
      timeRange.endDate,
    )
      .then(data => setMetricData(data))
      .catch(error => console.error('error fetching metric data:',error));
  },[timeRange,data,id]);

  const auth = useAuth();
  const isSemantu = auth.userAccount.email.includes('@semantu.com');

  return (
    <>
      <div>
        {data && <ShapeDetail data={data} />}
        {isSemantu && data && (metricData ? <AdChart
          data={metricData}
          metric={engagementMetric}
          onMetricChange={setEngagementMetric}
          timeRange={timeRange}
          onTimeRangeChange={setTimeRange}
        /> : <Spinner />)}
      </div>
      <AdChildren id={id} shapeType="campaign" label="AdSets" />
    </>

  );
}

export default CampaignManagementDetail;