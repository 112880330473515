import {Person} from 'lincd-schema/lib/shapes/Person';
import {URI} from 'lincd/lib/utils/URI';

export function getPersonFromUsername(username: string) {
  let person = Person.getLocalInstancesByType().find(p => p.givenName === username);
  if(!person) {
    person = Person.getFromURI(process.env.SITE_ROOT + '/submitperson/' + URI.sanitize(username));
    person.givenName = username;
    person.save();
  }
  return person;
}