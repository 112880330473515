import React,{useEffect,useState} from 'react';
import {DefaultLayout} from '../layout/DefaultLayout';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {ROUTES} from '../routes';
import ShapeDetail from '../components/ShapeDetail';
import {generatePath,Link,useLocation} from 'react-router-dom';
import {DynamicObject} from '../utils';
import {packageName} from '../package';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import * as echarts from 'echarts/core';
import {LineChart} from 'echarts/charts';
import {GridComponent,MarkAreaComponent,TitleComponent,TooltipComponent} from 'echarts/components';
import {CanvasRenderer} from 'echarts/renderers';
import {titanAds} from 'titan-ads/lib/ontologies/titan-ads';
import dayjs from 'dayjs';
import {TimeRangeProps} from '../types';
import {AdChart} from '../components/AdChart';
import {AdChildren} from '../components/AdChildren';
import { Spinner } from '../components/Spinner';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import AdSetActionDropdown from '../components/AdSetActionDropdown';
import './AdSetManagementDetail.scss';
import style from './AdSetManagementDetail.scss.json';

echarts.use([TitleComponent,TooltipComponent,GridComponent,LineChart,CanvasRenderer,MarkAreaComponent]);

function AdSetManagementDetail()
{
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uri = queryParams.get('uri');
  const id = uri.split('/').pop();

  const [adsetData, setAdsetData] = useState<DynamicObject>();

  const fetchSingleAdSetData = async () => {
    if (!uri) return;
    try {
      const data = await Server.call(packageName, 'loadSingleAdSetData', uri);
      if (data) {
        setAdsetData(data);
      } else {
        setAdsetData(null);
      }
    } catch (error) {
      console.error('Error fetching AdSet data:', error);
      setAdsetData(null);
    }
  };

  useEffect(() => {
    fetchSingleAdSetData();
  }, [uri]);

  return (
    <DefaultLayout>
      <div className={style.headerWrapper}>
        <h1 className={style.header}>
          <Link to={ROUTES.views.path}>Views</Link> &raquo;
          <Link to={generatePath(ROUTES.view_detail.path,{detail: 'adset-management'})}> AdSet Management</Link> &raquo; {id}
        </h1>
        <AdSetActionDropdown selectedRowData={adsetData ? [adsetData] : []} onComplete={fetchSingleAdSetData}/>
      </div>
      <AdSetDetails id={uri} data={adsetData} />
    </DefaultLayout>
  );
}

export function AdSetDetails({id, data}: {id: string, data?: DynamicObject})
{
  const [engagementMetric,setEngagementMetric] = useState<string>('dailyImpressions');
  const [metricData,setMetricData] = useState<DynamicObject>();

  // set default time range to last 7 days
  const [timeRange,setTimeRange] = useState<TimeRangeProps>({
    value: 'last7days',
    startDate: dayjs().subtract(7,'day').format('YYYY-MM-DD'), // default is 7 days ago,
    endDate: dayjs().format('YYYY-MM-DD'), // default is today
  });

  // fetch engagement metric data by uri
  useEffect(() => {
    if (!data && !id) return;

    Server.call(
      packageName,
      'getObservationsByShape',
      id,
      AdSet,
      [titanAds.dailyClicks,titanAds.dailyImpressions,titanAds.bidAmount,titanAds.dailySpend],
      timeRange.startDate,
      timeRange.endDate,
    )
      .then(data => setMetricData(data))
      .catch(error => console.error('error fetching metric data:',error));
  },[timeRange, data, id]);

  const auth = useAuth();
  const isSemantu = auth.userAccount.email.includes('@semantu.com');
  return (
    <>
      <div>
        {data ? <ShapeDetail data={data} /> : <Spinner />}
        {isSemantu && data && (metricData ? <AdChart
          data={metricData}
          metric={engagementMetric}
          onMetricChange={setEngagementMetric}
          timeRange={timeRange}
          onTimeRangeChange={setTimeRange}
        /> : <Spinner />)}
      </div>
      <AdChildren id={id} shapeType="adSet" label="Ads" />
    </>
  );
}

export default AdSetManagementDetail;