import * as React from 'react';
import { cl } from 'lincd/lib/utils/ClassNames';
import './Select.scss';
import style from './Select.scss.json';

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
    
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
    ({ className, children, ...rest }, ref) => (
      <select
        className={cl(style.root, className)}
        ref={ref}
        {...rest}
      >
        {children}
      </select>
    ),
);  
  
Select.displayName = "Select";