import {ProvideAuth} from 'lincd-auth/lib/hooks/useAuth';
import {AppRoot} from 'lincd-server-utils/lib/components/AppRoot';
import {Body} from 'lincd-server-utils/lib/components/Body';
import {Head} from 'lincd-server-utils/lib/components/Head';
import {Suspense, useContext, useEffect, useState} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {Error} from './components/Error';
import {ContextProvider as DashboardContextProvider} from './contexts/Dashboard';
import {ContextProvider as InputsContextProvider} from './contexts/AdInputs';
import {Spinner} from './components/Spinner';
import ThemeProvider, {ThemeContext} from './contexts/Theme';
import AppRoutes, {ROUTES} from './routes';

//Note that by default LINCD apps are set up with support for SCSS (sass) and CSS Modules
//So any .scss file needs to be imported by itself
import './App.scss';
//and then the .scss.json file needs to be imported to access the class names (this file will be automatically generated)
import style from './App.scss.json';
import { RowProvider } from './hooks/useRowContext';

export default function App() {
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);

    //prevent number input from changing on scroll
    document.addEventListener("wheel", function(event){
      if(document.activeElement['type'] === "number"){
        document.activeElement['blur']();
      }
    });
  }, []);

  return (
    <AppRoot>
      <Head>
        <></>
        <link href="https://cdn.jsdelivr.net/npm/react-day-picker@9.2.1/src/style.min.css" rel="stylesheet" />
        {/*  Add tags to html <head> here, for example, a font <link href='https://fonts.someapi.com/...' />*/}
      </Head>
      {domLoaded && (
        <ThemeProvider>
          <RowProvider>
            <BodyContent />
          </RowProvider>
        </ThemeProvider>
      )}
    </AppRoot>
  );
}

const BodyContent = () => {
  const {isLight} = useContext(ThemeContext);

  useEffect(() => {
    console.log(isLight);
  }, [isLight]);

  return (
    <Body
      routes={ROUTES}
      pageStyles={style}
      className={isLight ? style.App : style.AppDark}
    >
      <Suspense fallback={<Spinner />}>
        <ErrorBoundary FallbackComponent={Error}>
          <ProvideAuth>
            <DashboardContextProvider>
              <InputsContextProvider>
                <AppRoutes />
              </InputsContextProvider>
            </DashboardContextProvider>
          </ProvideAuth>
        </ErrorBoundary>
      </Suspense>
    </Body>
  );
};
