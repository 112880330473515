import React from 'react';
import './TableView.scss';
import styles from './TableView.scss.json';
import { Spinner } from './Spinner';

const TableView = ({ data, isLoading }: { data: any[], isLoading: boolean }) => {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Metric</th>
            <th>Traffic</th>
            {/* <th>Tracker/Advertiser P/L</th>
            <th>Tracker/Advertiser CVR</th>
            <th>Tracker/Advertiser CPA</th>
            <th>Tracker/Advertiser ROAS</th> */}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={6} style={{ textAlign: 'center' }}>
                <div style={{textAlign: 'center'}}>
                <Spinner />
                </div>
              </td>
            </tr>
          ) : data.length === 0 ? (
            <tr>
              <td colSpan={6} style={{ textAlign: 'center' }}>
                No data available
              </td>
            </tr>
          ) : (
            data.map((row, index) => (
              <tr key={index}>
                <td>{row.metric}</td>
                <td>{row.quoraPL}</td>
                {/* <td>{row.advertiserPL}</td>
                <td>{row.cvr}</td>
                <td>{row.cpa}</td>
                <td>{row.roas}</td> */}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableView;
