// src/components/AdActionDropdown.tsx
import React from 'react';
import ApproveActionModal from './ApproveActionModal';
import { useBatchActions } from '../hooks/useBatchActions'; // Custom hook to handle actions

interface ApprovalActionDropdownProps {
  selectedRows: any[]; // Accept selectedRows as a prop
  onComplete?: () => void;
  buttons?:boolean
}

const ApprovalActionDropdown: React.FC<ApprovalActionDropdownProps> = ({ selectedRows, onComplete,buttons }) => {
  const { approvalBatchActions } = useBatchActions();

  return ( 
  <ApproveActionModal
    buttons={buttons}
    actions={approvalBatchActions} 
    selectedRows={selectedRows} 
    onComplete={onComplete}
  />
);
};

export default ApprovalActionDropdown;
