import React, {useEffect, useState, useCallback, useContext} from 'react';
import {Ad} from 'titan-ads/lib/shapes/TitanShapes';
import {NodeShape} from 'lincd/lib/shapes/SHACL';
import {TitanQueryConfig, loadAdData, isDateToday} from '../utils';
import {Spinner} from '../components/Spinner';
import {InstanceOverview} from '../components/InstanceOverview';
import {debounce} from 'lodash';
import {ThemeContext} from '../contexts/Theme';
import AdActionDropdown from '../components/AdActionDropdown';
import {useCustomColumns} from '../hooks/useCustomColumns';
import { useNavigate } from 'react-router-dom';
import './AdManagement.scss';
import styles from './AdManagement.scss.json';
import DatePickerFilter from '../components/DatePickerFilter';
import {TimeRangeProps} from '../types';
import dayjs from 'dayjs';
import { DashboardContext } from '../contexts/Dashboard';

interface DynamicObject {
  [key: string]: any;
}

interface CustomColumn {
  property?: {label?: string};
  label: string;
  renderCell: (row: any) => any; // Using 'any' for the row parameter
}

interface AdManagementProps {
  batchActions?: any; // Adjust the type as needed
}

function AdManagement({batchActions}: AdManagementProps) {
  const {isLight} = useContext(ThemeContext); // Access the theme context
  const [instances, setInstances] = useState<DynamicObject[]>();
  const [sorting, setSorting] = React.useState<TitanQueryConfig['sortBy'][]>([
    {
      id: 'clicks',
      desc: true,
    },
  ]);
  const [pagination, _setPagination] = useState({pageIndex: 0, pageSize: 10}); // Pagination state
  const [totalItems, setTotalItems] = useState(0); // Total number of items on the server
  const [searchQuery, setSearchQuery] = useState('');
  const [filtersData, setFiltersData] = useState([{ field: 'isActive', value: 'Yes', operator: 'equal' }]);
  const [dataLoading, setDataLoading] = useState(true);

  // set default time range to last 7 days
  const [timeRange,setTimeRange] = useState<TimeRangeProps>({
    value: 'last7days',
    startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'), // default is 7 days ago
    endDate: dayjs().format('YYYY-MM-DD') // default is today,
  });

  const handleTimeRangeChange = (newTimeRange: TimeRangeProps) => {
    setTimeRange(newTimeRange);
  };

  const navigate = useNavigate();
  const shape: NodeShape = Ad.shape;

  const setPagination = (val) => {
    return _setPagination(val);
  };

  // const debouncedSetSearchQuery = useCallback(
  //   debounce((value) => setSearchQuery(value), 500), // 500ms debounce delay
  //   []
  // );

  const debouncedSetSearchQuery = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setPagination((prev) => ({...prev, pageIndex: 0})); // Reset to first page
    }, 500), // 500ms debounce delay
    [],
  );

  const handleCheckboxActiveOnly = (checked:boolean) => {
    setFiltersData((prev) => {
      if(!prev) {
        prev = [];
      }
      if(checked) {
        return [...prev, { field: 'isActive', value: 'Yes', operator: 'equal' }];
      } else {
        return prev.filter(filter => filter.field !== 'isActive');
      }
    });
  };


  async function fetchAdData(filtersValue?:any) {
    if(!filtersValue) {
      filtersValue = filtersData;
    }
    try {
      setDataLoading(true);
      const adData = await loadAdData({
        searchQuery: searchQuery,
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
        sorting: sorting,
        filters: Array.isArray(filtersValue) ? filtersValue : undefined,
        timeRange,
      });
      setInstances(adData.instances);
      setTotalItems(adData.numInstances);
      setDataLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setInstances([]);
    }
  }

  useEffect(() => {
      fetchAdData();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    searchQuery,
    filtersData,
    timeRange,
  ]);

  // const handleFilterInstances = (data: any) => {
  //   if (typeof data === 'boolean' && data) {
  //     fetchAdData();
  //   } else if (data?.numInstances > 0) {
  //     setFilterActive(true);
  //     setInstances(data.instances as DynamicObject[]);
  //     // const totalPages = Math.ceil(data.numInstances / pagination.pageSize);
  //     // if (totalPages < pagination.pageIndex) {
  //     //   _setPagination((prev) => ({...prev, pageIndex: totalPages - 1}));
  //     //   setTotalItems(data.numInstances);
  //     // }
  //   }
  // };

  const customColumns = useCustomColumns(instances, {
    //to indicate the column, use the same key here as in the mapping object in views/AdManagement.tsx
    status: {
      className: (value, row: DynamicObject) => {
        //you can use the row object if you need to access other values in the row
        if (value === 'PAUSED') {
          return styles.lightRed;
        }
        return '';
      },
    },
    shortAdName: {
      className: (value, row: DynamicObject) => {
        //you can use the row object if you need to access other values in the row
        const status = row.status;
        if (status === 'PAUSED') {
          return styles.lightRed;
        }
        return '';
      },
    },
    adOptimized: {
      className: (value, row: DynamicObject) => {
        //Show the cell in green if the value is 'Yes'.
        if (value === 'Yes') {
          return styles.green;
        }
        // return styles.lightRed;
      },
    },
    $adCPC: {
      className: (value, row: DynamicObject) => {
        //Show the cell as green if the value is lower than adGrossPL value by the calculation of adGrossPL/2.262, show as yellow if the value is lower than adGrossPL value by the calculation of adGrossPL/1.5, and show as red if the value eqiuals higher than adGrossPL
        const adGrossPL = row.$adGrossPL;
        if(!value) return;
        if (value < adGrossPL / 2.262) {
          return styles.green;
        } else if (value < adGrossPL / 1.5) {
          return styles.yellow;
        } else if (value >= adGrossPL) {
          return styles.red;
        }
      },
    },
    $adSpendSinceLastSale: {
      className: (value, row: DynamicObject) => {
        //Show the cell as green if the value is lower than adGrossPL value by the calculation of adGrossPL/2.262, show as yellow if the value is lower than adGrossPL value by the calculation of adGrossPL/1.5, and show as red if the value eqiuals higher than adGrossPL
        const adGrossPL = row.$adGrossPL;
        const adCPC = row.$adCPC;
        value = row.$adSpendSinceLastSale;
        if(!value) {
          return;
        }
        if(adCPC === 0 || adGrossPL === 0) return;
        if (adCPC < adGrossPL / 2.262) {
          return styles.green;
        } else if (adCPC < adGrossPL / 1.5) {
          return styles.yellow;
        } else if (adCPC >= adGrossPL) {
          return styles.red;
        }
      },
    },
    //Ad Gross P&L is red if negative, green if positive, and yellow if zero
    $adGrossPL: {
      className: (value, row: DynamicObject) => {
        //get number from the row
        value = row.$adGrossPL;
        if (value < 0) {
          return styles.red;
        } else if (value > 0) {
          return styles.green;
        } else if (value === 0) {
          return styles.yellow;
        }
      },
    },
  });
  const detailsViewHandler = (row) => {
    navigate(`${location.pathname}/details?uri=${row.id}`);
  }

  // // trigger the fetchAdData function when the brandAccount changes from quickFilter
  const {curAccount} = useContext(DashboardContext);
  // useEffect(() => {
  //   setFiltersData((prev) => {
  //     if (!prev) {
  //       prev = [];
  //     }

  //     // set the brandAccount filter if the brandAccount option selected
  //     if (curAccount && curAccount.name !== undefined) {
  //       // remove the previous brandAccount filter if it exists
  //       prev = prev.filter(filter => filter.field !== 'brandAccount');

  //       // add the new brandAccount filter
  //       return [...prev, { field: 'brandAccount', value: curAccount.name, operator: 'equal' }];
  //     } else {
  //       return prev.filter(filter => filter.field !== 'brandAccount');
  //     }
  //   })
  // }, [curAccount]);

  return (
    <>
      {customColumns ? (
        <InstanceOverview
          instances={instances}
          shape={shape}
          customColumns={customColumns}
          isLoading={dataLoading}
          // defaultColumn={{
          //   meta: {
          //     className: (value,row) => 'status',
          //   }
          // }}
          pagination={pagination}
          resetFilter={(value:boolean) => fetchAdData(value)}
          totalItems={totalItems}
          filterInstances={(filters: any) => fetchAdData(filters)}
          quickFilter={[
            {
              property: 'status',
              label: 'Show only active ads',
              onChange: (checked:boolean) => handleCheckboxActiveOnly(checked),
              value: instances?.find(filter => filter.field === 'isActive')?.value === 'YES',
            },
            {
              property: 'brandAccount',
              onChange: (account:string) => fetchAdData([{ field: 'brandAccount', value: account, operator: 'equal' }]),
              value: curAccount?.name,
            }
          ]}
          onPaginationChange={setPagination}
          sorting={sorting}
          onSortingChange={setSorting}
          onSearchChange={debouncedSetSearchQuery}
          isLight={isLight}
          onDetailsClick={detailsViewHandler}
          batchActionDropdown={(selectedRowData) => (
            <AdActionDropdown
              selectedRowData={selectedRowData}
              onComplete={fetchAdData}
            />
          )}
          timeRange={timeRange}
          onTimeRangeChange={handleTimeRangeChange}
          // dateFilter={() => (
          //   <DatePickerFilter onDateSelect={setSelectedDate} mode={'multiple'} />
          // )}
        />
      ) : (<Spinner />)}
    </>
  );
}

export default AdManagement;
