import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';
import {TemplateList} from 'titan-ads/lib/components/TemplateList';
import {AdSet} from 'titan-ads/lib/shapes/TitanShapes';
import {ThemeContext} from '../contexts/Theme';
import '../pages/Quicklaunch.scss';
import style from '../pages/Quicklaunch.scss.json';
import {useSuspense} from '../utils';
import {Spinner} from './Spinner';
import { NodeSet } from 'lincd/lib/collections/NodeSet';
import { asset } from 'lincd/lib/utils/LinkedFileStorage';

const PlacementConstraints = [
  'Email Templates',
  'All Other Placements',
  // 'Only',
] as const;

const DeviceConstraints = [
  'No constraint',
  'Mobile & Desktop',
  'Choose specific device & browsers',
  // 'Only',
] as const;
const SpecificDevices = [
  'All Mobile Devices',
  'iOS',
  'Android',
  'Others',
] as const;
const SpecificBrowsers = [
  'All Desktop Browsers',
  'Chrome',
  'Safari',
  'Firefox',
  'Others',
] as const;
const GenderOptions = [
  'No constraint',
  'All genders only',
  'Choose specific genders',
  // 'Only',
] as const;

const GenderConstraints = [
  'Female AdSets only',
  'Male AdSets only',
  'Unknown AdSets only',
] as const;

type DeviceConstraint = (typeof DeviceConstraints)[number];
type SpecificDevice = (typeof SpecificDevices)[number];
type SpecificBrowser = (typeof SpecificBrowsers)[number];
type GenderOption = (typeof GenderOptions)[number];
type GenderConstraint = (typeof GenderConstraints)[number];
type PlacementConstraint = (typeof PlacementConstraints)[number];

interface ConstraintProps {
  constraintAdSets: ShapeSet<AdSet>;
  setConstraintAdSets: Dispatch<SetStateAction<ShapeSet<AdSet>>>;
}


export default function ConstraintComponent({...props}: ConstraintProps) {
  const [placement, setPlacement] = useState<PlacementConstraint>('All Other Placements');
  const [device, setDevice] = useState<DeviceConstraint>('No constraint');
  const [specificDevices, setSpecificDevices] = useState<SpecificDevice[]>([]);
  const [specificBrowsers, setSpecificBrowsers] = useState<SpecificBrowser[]>(
    [],
  );
  const [genderOption, setGenderOption] = useState<GenderOption>('No constraint');
  const [genders, setGenders] = useState<GenderConstraint[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [templates, setTemplates] = useState({read: () => new ShapeSet<AdSet>()});

  useEffect(() => {
    setTemplates(useSuspense(AdSet.loadAllTemplates()));
  },[])
  const forceRefreshList = () => setRefresh(!refresh);


  const onSelectPlacement = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as PlacementConstraint;
    setPlacement(value);
  };
  
  const onSelectDevice = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as DeviceConstraint;
    setDevice(value);
    // Reset specific devices and browsers if a general device is selected
    setSpecificDevices([]);
    setSpecificBrowsers([]);
  };
  
  const onSelectSpecificDevice = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as SpecificDevice;
    setSpecificDevices((prev) =>
      prev.includes(value)
        ? prev.filter((device) => device !== value)
        : [...prev, value],
    );
  };
  
  const onSelectSpecificBrowser = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as SpecificBrowser;
    setSpecificBrowsers((prev) =>
      prev.includes(value)
        ? prev.filter((browser) => browser !== value)
        : [...prev, value],
    );
  };
  
  const onSelectGenderOption = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as GenderOption;
    setGenderOption(value);
    // Reset genders if a specific gender option is deselected
    setGenders([]);
  };
  
  const onSelectGender = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as GenderConstraint;
    setGenders((prev) =>
      prev.includes(value)
        ? prev.filter((gender) => gender !== value)
        : [...prev, value],
    );
  };
  

  // useEffect(() => {
  //   setTemplates(useSuspense(AdSet.loadAllTemplates()));
  // }, [
  //   // device,
  //   // specificDevices,
  //   // specificBrowsers,
  //   // genderOption,
  //   // genders,
  //   // refresh,
  // ]);

  return (
    <>
    <div className={style.constraintOptions}>
    <div>
    <h3>Filter</h3>
    <div className={style.inputMulti}>
    <label htmlFor="placement">Placement</label>
      <select
        id="placementSelect"
        name="placement"
        value={placement}
        onChange={onSelectPlacement}
        className={style.select}
      >
        <option value="" disabled>
          Placement
        </option>
        {PlacementConstraints.map((constraint) => (
          <option key={constraint} value={constraint}>
            {constraint}
          </option>
        ))}
      </select>
    </div>
  
      <div className={style.inputMulti}>
        <label htmlFor="deviceSelect">Device & Browser</label>
        <select
          id="deviceSelect"
          name="device"
          value={device}
          onChange={onSelectDevice}
          className={style.select}
        >
          <option value="" disabled>
          Device & Browser
        </option>
          {DeviceConstraints.map((constraint) => (
            <option key={constraint} value={constraint}>
              {constraint}
            </option>
          ))}
        </select>
  
        {device === 'Choose specific device & browsers' && (
          <div className={style.inputMulti}>
            <span style={{fontSize:'13px', marginBottom:'5px'}}>
              Each selected field will include all templates with that device/browser setting
            </span>
            <div className={style.flexColumn}>
              <div>
                {SpecificDevices.map((specificDevice) => (
                  <div key={specificDevice}>
                    <label>
                      <input
                        type="checkbox"
                        name="specificDevice"
                        value={specificDevice}
                        checked={specificDevices.includes(specificDevice)}
                        onChange={onSelectSpecificDevice}
                      />
                      {specificDevice}
                    </label>
                  </div>
                ))}
              </div>
              <div>
                {SpecificBrowsers.map((specificBrowser) => (
                  <div key={specificBrowser} style={{ marginLeft: '20px' }}>
                    <label>
                      <input
                        type="checkbox"
                        name="specificBrowser"
                        value={specificBrowser}
                        checked={specificBrowsers.includes(specificBrowser)}
                        onChange={onSelectSpecificBrowser}
                      />
                      {specificBrowser}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
  
      <div className={style.inputMulti}>
        <label htmlFor="genderSelect">Gender</label>
        <select
          id="genderSelect"
          name="genderOption"
          value={genderOption}
          onChange={onSelectGenderOption}
          className={style.select}
        >
           <option value="" disabled>
          Gender
        </option>
          {GenderOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
  
        {genderOption === 'Choose specific genders' && (
          <div className={style.inputMulti}>
            {GenderConstraints.map((constraint) => (
              <div key={constraint}>
                <label>
                  <input
                    type="checkbox"
                    id={constraint}
                    name="gender"
                    value={constraint}
                    checked={genders.includes(constraint)}
                    onChange={onSelectGender}
                  />
                  {constraint}
                </label>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  
    <div style={{ maxHeight: '65vh', overflowY: 'auto', overflowX: 'hidden' }}>
      <Suspense fallback={<Spinner />}>
        <TemplateFilter
          {...props}
          placement={placement}
          device={device}
          specificDevices={specificDevices}
          specificBrowsers={specificBrowsers}
          genderOption={genderOption}
          genders={genders}
          refreshList={forceRefreshList}
          templateRes={templates}
        />
      </Suspense>
    </div>
  </div>
  </>
  );
}

interface TemplateFilterProps extends ConstraintProps
{
  device: DeviceConstraint;
  specificDevices: SpecificDevice[];
  placement: PlacementConstraint;
  specificBrowsers: SpecificBrowser[];
  genderOption: GenderOption;
  genders: GenderConstraint[];
  refreshList: () => void;
  templateRes: {read(): ShapeSet<AdSet>};
}

// function TemplateFilter({
//                           constraintAdSets,
//                           device,
//                           placement,
//                           specificDevices,
//                           specificBrowsers,
//                           genderOption,
//                           genders,
//                           refreshList,
//                           setConstraintAdSets,
//                           templateRes,
//                         }: TemplateFilterProps)
// {
//   const {isLight} = useContext(ThemeContext);

//   const templates = templateRes.read();

//   const [unselectedTemplates, setUnselectedTemplates] = useState(new NodeSet());
//   const [counterUnSelected, setCounterUnSelected] = useState(0);
//   const [searchQuery, setSearchQuery] = useState<string>('');
//   const [showUnselectedTemplate, setShowUnselectedTemplate] = useState(false);

//   const filteredTemplates = templates
//     .filter((template) => {
//       return !unselectedTemplates.has(template.namedNode);
//     })
//     .filter((template) => {
//       // console.log('tempalte', template);

//       // Filter by placement (currently only email is considered as filter)
//       if(placement === 'Email Templates' && template.placement !== 'Digest Emails') return false;
//       if(placement === 'All Other Placements' && template.placement === 'Digest Emails') return false;

//       if (
//         genderOption === 'All genders only' &&
//         template.targetsFemales &&
//         template.targetsMales &&
//         template.targetsUnknown
//       )
//         return true;
//       // template.targetsAllGenders = false; // reset the state if unchecked

//       if (genders.includes('Female AdSets only'))
//       {
//         if (!template.targetsMales && template.targetsFemales && !template.targetsUnknown) return true;
//         // template.targetsFemales = false; // reset the state if unchecked
//       }
//       if (genders.includes('Male AdSets only'))
//       {
//         if (template.targetsMales && !template.targetsFemales && !template.targetsUnknown) return true;
//         // template.targetsMales = false; // reset the state if unchecked
//       }
//       if (genders.includes('Unknown AdSets only')) {
//         if (!template.targetsMales && !template.targetsFemales && template.targetsUnknown) return true;
//         // template.targetsUnknown = false; // reset the state if unchecked
//       }
//       if (genderOption === 'No constraint') return true;
//       return false;
//     })
//     .filter((template) => {
//       // Filter by device constraints
//       if (device === 'No constraint') return true;
//       if(device === 'Mobile & Desktop') {
//         return template.deviceAndBrowser.toLowerCase().includes('Mobile & Desktop'.toLowerCase());
//       }
//       if (device === 'Choose specific device & browsers') {
//         // if (specificDevices.includes('All Mobile Devices')) return true;
//         const deviceMatch = specificDevices.some((specificDevice) =>
//           template.deviceAndBrowser
//             .toLowerCase()
//             .includes(specificDevice.toLowerCase()),
//         );
//         const browserMatch = specificBrowsers.some((specificBrowser) =>
//           template.deviceAndBrowser
//             .toLowerCase()
//             .includes(specificBrowser.toLowerCase()),
//         );
//         //NOTE: WITHIN mobile/desktop and browsers -> only one of them needs to match, so this is an OR operation
//         return deviceMatch || browserMatch;
//       }
//       return false;
//     });

//   //reset the unselected templates when the constraints change
//   useEffect(() => {
//     setUnselectedTemplates(new NodeSet());
//   },[
//     device,
//     placement,
//     specificDevices,
//     specificBrowsers,
//     genderOption,
//     genders,
//   ])

//   useEffect(() => {
//     //I believe this code compares two sets and sees if there is any difference
//     if (
//       constraintAdSets.concat(filteredTemplates).size !==
//         constraintAdSets.size ||
//       filteredTemplates.concat(constraintAdSets).size !== filteredTemplates.size
//     )
//       setConstraintAdSets(filteredTemplates);
//   }, [filteredTemplates]);

//   const removeSpecificTemplate = (template: AdSet) => {
//     setUnselectedTemplates((prev) => new NodeSet([...prev, template.namedNode]));
//   }

//    // Restore templates based on excluded templates
//   const restoreSpecificTemplate = (template: AdSet) => {
//     setUnselectedTemplates((prev) => new NodeSet([...prev].filter((node) => node !== template.namedNode)));
//   }

//   // Filter templates based on search query
//   const displayedTemplates = filteredTemplates.filter((template) =>
//     template.name.toLowerCase().includes(searchQuery.toLowerCase()),
// );

//   useEffect(() => {
//     setCounterUnSelected(unselectedTemplates.size);
//   }, [unselectedTemplates]);

//   return (
//     <>
//       {location.pathname === '/quicklaunch' && (
//         <>
//         <div className={style.headerTemplates}>
//           <div className={style.headerInnerTemplates}>
//           <div>
//           <h3>
//             {filteredTemplates.size} AdSet template
//             {filteredTemplates.size !== 1 ? 's' : ''}
//           </h3>
//           <div className={style.labelSmall}>
//             Click on a template to include it. Change parameters to reset the list.
//           </div>
//           </div>
//           <div className={style.excludedItems} onClick={() => setShowUnselectedTemplate(!showUnselectedTemplate)}>
//             {/* <p>Excluded Templates</p> */}
//             <img src={asset('/images/exemption.png')} alt="excluded-item" onClick={refreshList} />
//             {counterUnSelected > 0 ? (
//             <p className={style.counter}>{counterUnSelected}</p>
//           ) : null}
//           </div>
//           </div>
//           <div className={style.searchTemplates}>
//           <div style={{ position: 'relative', display: 'inline-block', width:'100%' }}>
//             <input
//               type="text"
//               placeholder="Search templates..."
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)}
//               style={{ paddingLeft: '30px' }}
//             />
//             <img
//               src={asset('/images/search_template.png')}
//               alt="Search"
//               style={{
//                 position: 'absolute',
//                 left: '10px',
//                 top: '50%',
//                 transform: 'translateY(-50%)',
//                 width: '16px',
//                 height: '16px',
//                 pointerEvents: 'none', // Prevent icon from intercepting clicks
//               }}
//             />
//           </div>

//           </div>
//           </div>
        
//           <Suspense fallback={<Spinner />}>
//           <div className={style.tableTemplates}>
//               <div>
//                 <input type="checkbox" />
//               </div>
//               <div className={style.toggleValue}>Template Name</div>
//               <div className={style.toggleValue}>Target Placement</div>
//               <div className={style.toggleValue}>Target Gender</div>
//               <div className={style.toggleValue}>Target Device & Browser</div>
//           </div>
//             {counterUnSelected > 0 && showUnselectedTemplate ? (
//             <>
//               <TemplateList
//               isLightTheme={isLight}
//               of={unselectedTemplates}
//               refreshList={refreshList}
//               onSelect={restoreSpecificTemplate}
//               />
//               <p className={style.notesTemplate}>Click the checkbox to restore the template</p>
//             </>
//             ) : (
//             <TemplateList
//               isLightTheme={isLight}
//               of={searchQuery ? displayedTemplates : filteredTemplates}
//               refreshList={refreshList}
//               onSelect={removeSpecificTemplate}
//             />
//             )}
//           </Suspense>
//         </>
//       )}
//     </>
//   );
// }

function TemplateFilter({
  constraintAdSets,
  device,
  placement,
  specificDevices,
  specificBrowsers,
  genderOption,
  genders,
  refreshList,
  setConstraintAdSets,
  templateRes,
}: TemplateFilterProps) {
  const { isLight } = useContext(ThemeContext);

  const templates = templateRes.read();

  const [SelectedTemplates, setSelectedTemplates] = useState(new NodeSet());
  const [counterSelected, setCounterSelected] = useState(0);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [allTemplates, setAllTemplates] = useState(false);
  const [showSelectedTemplate, setShowSelectedTemplate] = useState(false);

  const filteredTemplates = templates
  .filter((template) => {
    // This filter no longer excludes selected templates.
    return true; // Include all templates, selected or unselected
  })
  .filter((template) => {
    // Filter by placement
    if (placement === 'Email Templates') return template.placement === 'Digest Emails';
    if (placement === 'All Other Placements') return template.placement !== 'Digest Emails';
    return true;
  })
  .filter((template) => {
    // Filter by gender option
    if (genderOption === 'All genders only') {
      return (
        template.targetsFemales &&
        template.targetsMales &&
        template.targetsUnknown
      );
    }

    if (genders.includes('Female AdSets only')) {
      return template.targetsFemales && !template.targetsMales && !template.targetsUnknown;
    }

    if (genders.includes('Male AdSets only')) {
      return template.targetsMales && !template.targetsFemales && !template.targetsUnknown;
    }

    if (genders.includes('Unknown AdSets only')) {
      return template.targetsUnknown && !template.targetsMales && !template.targetsFemales;
    }

    if (genderOption === 'No constraint') return true;

    return false;
  })
  .filter((template) => {
    // Filter by device constraints
    if (device === 'No constraint') return true;
    if (device === 'Mobile & Desktop') {
      return template.deviceAndBrowser.toLowerCase().includes('Mobile & Desktop'.toLowerCase());
    }
    if (device === 'Choose specific device & browsers') {
        const deviceMatch = specificDevices.some((specificDevice) =>
          template.deviceAndBrowser
            .toLowerCase()
            .includes(specificDevice.toLowerCase()),
        );
        const browserMatch = specificBrowsers.some((specificBrowser) =>
          template.deviceAndBrowser
            .toLowerCase()
            .includes(specificBrowser.toLowerCase()),
        );
      return deviceMatch || browserMatch;
    }
    return false;
  });

  useEffect(() => {
    setSelectedTemplates(new NodeSet());
  }, [
    device,
    placement,
    specificDevices,
    specificBrowsers,
    genderOption,
    genders,
  ]);

  useEffect(() => {
    if (device === 'No constraint' && placement === 'All Other Placements') {
      setSelectedTemplates(new NodeSet());
    }
  }, [device, placement]);

  useEffect(() => {
    let updatedSet: ShapeSet<AdSet>;
    
    if (SelectedTemplates && SelectedTemplates.size > 0) {
      // If there are selected templates, use only SelectedTemplates
      updatedSet = new ShapeSet<AdSet>(Array.from(SelectedTemplates).map(node => templates.find(template => template.namedNode === node)!));
    } else {
      // If there are no selected templates, use all the filtered templates
      updatedSet = new ShapeSet<AdSet>(filteredTemplates);
    }
    
    // If the constraintAdSets has changed, update it
    if (updatedSet.size !== constraintAdSets.size) {
      setConstraintAdSets(updatedSet);
    }
  }, [
    filteredTemplates, 
    SelectedTemplates, 
    device,
    placement,
    specificDevices,
    specificBrowsers,
    genderOption,
    genders
  ]);  
  

  const IncludeTemplates = (template: AdSet) => {
    setSelectedTemplates((prev) => {
      const updatedTemplates = new NodeSet([...prev]);
      if (updatedTemplates.has(template.namedNode)) {
        updatedTemplates.delete(template.namedNode);
      } else {
        updatedTemplates.add(template.namedNode);
      }
      return updatedTemplates;
    });
  };

  const IncludeAllTemplates = (selectedTemplate: boolean) => {
    if (selectedTemplate) {
      setSelectedTemplates(new NodeSet(filteredTemplates.map(template => template.namedNode)));
      setAllTemplates(true);
    } else {
      setSelectedTemplates(new NodeSet());
      setAllTemplates(false);
    }
  };

  const restoreSpecificTemplate = (template: AdSet) => {
    setSelectedTemplates((prev) =>
      new NodeSet([...prev].filter((node) => node !== template.namedNode))
    );
  };

  const displayedTemplates = filteredTemplates.filter((template) =>
    template.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    setCounterSelected(SelectedTemplates.size);
  }, [SelectedTemplates]);

  return (
    <>
      {location.pathname === '/quicklaunch' && (
        <>
          <div className={style.headerTemplates}>
            <div className={style.headerInnerTemplates}>
              <div>
                <h3>
                  {filteredTemplates.size} AdSet template
                  {filteredTemplates.size !== 1 ? 's' : ''}
                </h3>
                <div className={style.labelSmall}>
                  Click on a template to include it. Change parameters to reset the list.
                </div>
              </div>
              <div
                className={style.excludedItems}
                onClick={() => setShowSelectedTemplate(!showSelectedTemplate)}
              >
                <img
                  src={asset('/images/exemption.png')}
                  alt="excluded-item"
                  onClick={refreshList}
                />
                {counterSelected > 0 ? (
                  <p className={style.counter}>{counterSelected}</p>
                ) : null}
              </div>
            </div>
            <div className={style.searchTemplates}>
              <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                <input
                  type="text"
                  placeholder="Search templates..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{ paddingLeft: '30px' }}
                />
                <img
                  src={asset('/images/search_template.png')}
                  alt="Search"
                  style={{
                    position: 'absolute',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '16px',
                    height: '16px',
                    pointerEvents: 'none',
                  }}
                />
              </div>
            </div>
          </div>

          <Suspense fallback={<Spinner />}>
            <div className={style.tableTemplates}>
              <div>
                <input type="checkbox" checked={allTemplates} onChange={() => IncludeAllTemplates(!allTemplates)} />                
              </div>
              <div className={style.toggleValue}>Template Name</div>
              <div className={style.toggleValue}>Target Placement</div>
              <div className={style.toggleValue}>Target Gender</div>
              <div className={style.toggleValue}>Target Device & Browser</div>
            </div>
            {showSelectedTemplate && SelectedTemplates.size > 0 ? (
              <>
              <TemplateList
                isLightTheme={isLight}
                of={SelectedTemplates}
                refreshList={refreshList}
                SelectedTemplates={SelectedTemplates}
                onSelect={restoreSpecificTemplate}
              />
              <p className={style.notesTemplate}>
                Click the checkbox to restore the template
              </p>
              </>
            ) : (
              <TemplateList
              isLightTheme={isLight}
              of={searchQuery ? displayedTemplates : filteredTemplates}
              refreshList={refreshList}
              SelectedTemplates={SelectedTemplates}
              onSelect={IncludeTemplates}
              />
            )}
          </Suspense>
        </>
      )}
    </>
  );
}