import React, { useEffect, useState } from 'react';
import './RefreshQuoraData.scss';
import { default as style } from './RefreshQuoraData.scss.json';

interface DatePickerInputProps {
  handleStartDate: (date?: string, customDate?: boolean) => void;
}

export default function DatePickerInput({ handleStartDate }: DatePickerInputProps) {
  const [selectedOption, setSelectedOption] = useState('');
  const [customDateRange, setCustomDateRange] = useState({ start: '', end: '' });
  const selectedOptionRef = React.useRef(selectedOption);
  selectedOptionRef.current = selectedOption;
  
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'start' && new Date(value) > new Date()) {
      alert('Start date cannot be in the future.');
      return;
    }

    setCustomDateRange((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const dateMap: { [key: string]: number } = {
      yesterday: -1,
      '7days': -7,
      '14days': -14,
      '30days': -30,
    };

    let newStartDate: Date | null = null;

    if (selectedOption in dateMap) {
      newStartDate = new Date();
      newStartDate.setDate(newStartDate.getDate() + dateMap[selectedOption]);
    } else if (selectedOption === 'custom' && customDateRange.start) {
      newStartDate = new Date(customDateRange.start);
    }

    if (newStartDate && !isNaN(newStartDate.getTime())) {
      handleStartDate(newStartDate.toISOString().split('T')[0], selectedOption === 'custom');
    } else if (selectedOption === 'custom') {
      handleStartDate('', true);
    }
  }, [selectedOption, customDateRange]);
  

  return (
    <div className={style.datePickerCustom}>
      <select
        data-cy="choose-date"
        name="date"
        className={style.dropdownCustom}
        value={selectedOption}
        onChange={handleOptionChange}
      >
        <option value="">Choose date range</option>
        <option value="yesterday">Yesterday</option>
        <option value="7days">Last 7 days</option>
        <option value="14days">Last 14 days</option>
        <option value="30days">Last 30 days</option>
        <option value="custom">Custom Dates</option>
      </select>
      {selectedOption === 'custom' && (
        <div className={style.datePickerInput}>
          <h5>Start Date</h5>
          <input
            type="date"
            name="start"
            value={customDateRange.start}
            onChange={handleDateChange}
          />
          <h5>End Date</h5>
          <input
            type="date"
            name="end"
            value={new Date().toISOString().split('T')[0]}
            readOnly
          />
        </div>
      )}
    </div>
  );
}
